import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  MatCheckboxModule, MatButtonModule, MatDialogModule,
  MatSlideToggleModule, MatSelectModule, MatStepperModule, MatInputModule, MatIconModule
} from '@angular/material';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    MatButtonModule,
    MatCheckboxModule,
    MatSlideToggleModule,
    MatSelectModule,
    MatStepperModule,
    MatInputModule,
    MatIconModule,

  ],
  exports: [
    MatButtonModule,
    MatCheckboxModule,
    MatDialogModule,
    MatSlideToggleModule,
    MatSelectModule,
    MatStepperModule,
    MatInputModule,
    MatIconModule
  ],
})
export class CustomMaterialModule { }
