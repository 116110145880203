import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { RouteReuseStrategy } from '@angular/router';
import { environment } from '../../environments/environment';
import { RequestOptions } from '@angular/http';
import { sp } from '@angular/core/src/render3';

@Injectable({
  providedIn: 'root'
})
export class SpaceService {
  bookStatus:boolean=false;
  constructor(private http: HttpClient) { }

  createSpace(spaceForm: any, files: any, publish: any, not_avail_ar: any,avail_ar:any) {
    //console.log(not_avail_ar,"array");
    //console.log(avail_ar,"array");
    const body = {
      spaceTitle: spaceForm.spaceTitle,
      space_type: spaceForm.spaceType,
      free: spaceForm.free,
      capacity_of_space: spaceForm.spaceCapacity,
      other_capacity_of_space: spaceForm.otherSpaceCapacity,
      overview: spaceForm.spaceDescribe,

      address1: spaceForm.inputAddress,
      address2: spaceForm.inputAddress2,
      city: spaceForm.inputCity,
      state: spaceForm.inputState,
      postcode: spaceForm.inputZip,

      from_date: spaceForm.from_date,
      to_date: spaceForm.to_date,

      pricetype: spaceForm.priceType,
      pricerate: spaceForm.price,
      minbookingdays: spaceForm.minbookingdays,
      minbookinghours: spaceForm.minbookinghours,

      cancel_policy: spaceForm.cancel_policy,
      amenities_id: spaceForm.amenity,
      otheramenities: spaceForm.otherAmenities,
      ruleofuse: spaceForm.rulesOfUse,
      owner_id: spaceForm.owner_id,
      publish: publish,
      fileUpload: files,
      not_avail_ar: not_avail_ar,
      avail_ar:avail_ar
    };
    //console.log('add space body',body,spaceForm);
    
    const fd = new FormData();
    fd.append('spaceTitle',spaceForm.spaceTitle);
    fd.append('space_type',spaceForm.spaceType);
    fd.append('free', spaceForm.free);
    fd.append('capacity_of_space',spaceForm.spaceCapacity);
    fd.append('other_capacity_of_space',spaceForm.otherSpaceCapacity);
    fd.append('overview',spaceForm.spaceDescribe);
    fd.append('address1',spaceForm.inputAddress);
    fd.append('address2', spaceForm.inputAddress2);
    fd.append('city',spaceForm.inputCity);
    fd.append('state',spaceForm.inputState);
    fd.append('postcode',spaceForm.inputZip);
    fd.append('from_date',spaceForm.from_date);
    fd.append('to_date',spaceForm.to_date);
    fd.append('pricetype',spaceForm.priceType);
    fd.append('pricerate',spaceForm.price);
    fd.append('minbookingdays',(spaceForm.minbookingdays)?spaceForm.minbookingdays:0);
    fd.append('minbookinghours',spaceForm.minbookinghours);
    fd.append('cancel_policy',spaceForm.cancel_policy);
    fd.append('amenities_id',spaceForm.amenity);
    fd.append('ruleofuse',spaceForm.rulesOfUse);
    fd.append('owner_id',spaceForm.owner_id);
    fd.append('publish',publish);
    fd.append('not_avail_ar',JSON.stringify(not_avail_ar));
    fd.append('avail_ar',JSON.stringify(avail_ar));


    const other_amenities = spaceForm.otherAmenities;
    //console.log(other_amenities,fd);
    
    other_amenities.forEach(element1 => { 
      fd.append('otheramenities[]',element1);
    });  
    //console.log(files,"files");
    
    files.forEach(element => {     
     
      const date = new Date().valueOf();
      let text = '';
      const possibleText = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      for (let i = 0; i < 5; i++) {
        text += possibleText.charAt(Math.floor(Math.random() *    possibleText.length));
      }
      // Replace extension according to your media type
      const imageName = date + '.' + text + '.jpeg';
      // call method that creates a blob from dataUri
      var base64result = element[2].split(',')[1];
      //console.log(text,imageName,'element[2]',base64result);

      const imageBlob = this.dataURItoBlob(base64result);
      const imageFile = new File([imageBlob], imageName, { type: 'image/jpeg' });
      //console.log('imageFile',imageFile,imageBlob);
      
      fd.append('fileUpload[]',imageFile);

    });

    


    // //console.log(JSON.stringify(body));
  return new Promise((resolve, reject) => {

      this.http.post(environment.apiURL + '/addspace', fd)
        .subscribe(res => {
          resolve(res);
          //console.log(res);
        }, (err) => {
          reject(err);
        }, () => {
        });
    });
  }
  dataURItoBlob(dataURI) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'image/jpeg' });    
    return blob;
 }
  // modify space
  modifySpace(spaceForm, old, files, not_avail_ar: any,avail_ar:any) {
    // //console.log(JSON.stringify(spaceForm));
    // //console.log(JSON.stringify(old));
    //console.log(not_avail_ar);
    
    const body = {
      spaceTitle: spaceForm.spaceTitle,
      space_type: spaceForm.spaceType,
      free: spaceForm.free,
      capacity_of_space: spaceForm.spaceCapacity,
      other_capacity_of_space: spaceForm.otherSpaceCapacity,
      overview: spaceForm.spaceDescribe,

      address1: spaceForm.inputAddress,
      address2: spaceForm.inputAddress2,
      city: spaceForm.inputCity,
      state: spaceForm.inputState,
      postcode: spaceForm.inputZip,

      from_date: spaceForm.from_date,
      to_date: spaceForm.to_date,
      pricetype: spaceForm.priceType,
      pricerate: spaceForm.price,
      minbookingdays: spaceForm.minbookingdays,
      minbookinghours: spaceForm.minbookinghours,

      cancel_policy: spaceForm.cancel_policy,
      amenities_id: spaceForm.amenity,
      otheramenities: spaceForm.otherAmenities,
      ruleofuse: spaceForm.rulesOfUse,
      owner_id: spaceForm.owner_id,
      publish: spaceForm.publish,
      id: spaceForm.spaceId,
      hidden_img: old,
      fileUpload: files,
      not_avail_ar: not_avail_ar,
      avail_ar:avail_ar
    };
    // //console.log(JSON.stringify(body));

    return new Promise((resolve, reject) => {
      this.http.post(environment.apiURL + '/updatespace', JSON.stringify(body), {
        headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' })
      })
        .subscribe(res => {
          resolve(res);
          // //console.log(JSON.stringify(res));
        }, (err) => {
          reject(err);
        }, () => {
        });
    });
  }

  // //get banner on explore space page
  // getexploreBanner() {
  //   return new Promise((resolve, reject) => {
  //     this.http.get(environment.apiURL + '/getSpacesData')
  //       .subscribe(res => {
  //         resolve(res);
  //         // //console.log(JSON.stringify(res));
  //       }, (err) => {
  //         reject(err);
  //       });
  //   });

  // }
  // get all required data for creating space in create new space page
  getCreateSpaceFormData(body) {
    return new Promise((resolve, reject) => {
      this.http.post(environment.apiURL + '/getFormData', JSON.stringify(body), {
        headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' })
      })
        .subscribe(res => {
          resolve(res);
          // //console.log(JSON.stringify(res));
        }, (err) => {
          reject(err);
        });
    });

  }

  // get cities list of a state
  getCityList(state_id) {
    const body = { 'state_id': state_id };
    return new Promise((resolve, reject) => {
      this.http.post(environment.apiURL + '/City', JSON.stringify(body), {
        headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' })
      })
        .subscribe(res => {
          resolve(res);
          // //console.log(JSON.stringify(res));
        }, (err) => {
          reject(err);
        });
    });
  }

  // get all space created by a user
  SellerSpaceList(userID) {
    const body = { userID: userID };
    return new Promise((resolve, reject) => {
      this.http.post(environment.apiURL + '/getSpaces', JSON.stringify(body), {
        headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' })
      })
        .subscribe(res => {
          resolve(res);
          // //console.log(JSON.stringify(res));
        }, (err) => {
          reject(err);
        }, () => {
        });
    });
  }




  // get Data of Space to modify
  getModifySpaceData(userID, spaceID) {
    const body = { userID: userID, spaceID: spaceID };
    return new Promise((resolve, reject) => {
      this.http.post(environment.apiURL + '/getModifySpaces', JSON.stringify(body), {
        headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' })
      })
        .subscribe(res => {
          resolve(res);
          // //console.log(JSON.stringify(res));
        }, (err) => {
          reject(err);
        }, () => {
        });
    });
  }

  // delete space by the owner
  deleteSpace(user: any, spaceID: any) {
    // //console.log(user);
    const body = { userID: user.id, spaceID: spaceID };
    return new Promise((resolve, reject) => {
      this.http.post(environment.apiURL + '/delspace', JSON.stringify(body), {
        headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' })
      })
        .subscribe(res => {
          resolve(res);
          // //console.log(JSON.stringify(res));
        }, (err) => {
          reject(err);
        }, () => {
        });
    });
  }


  // get featured space on home page
  getFeaturedSpaces() {
    return new Promise((resolve, reject) => {
      this.http.post(environment.apiURL + '/getFeaturedSpaces', 'xyz', {
        headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' })
      })
        .subscribe(res => {
          resolve(res);
          // //console.log(JSON.stringify(res));
        }, (err) => {
          reject(err);
        }, () => {
        });
    });
  }

  // view a particular space
  viewSpace(data) {
    // const body = { 'id': spaceID , user_id: userID };
    return new Promise((resolve, reject) => {
      this.http.post(environment.apiURL + '/viewSpaces', JSON.stringify(data), {
        headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' })
      })
        .subscribe(res => {
          resolve(res);
          // //console.log(JSON.stringify(res));
        }, (err) => {
          reject(err);
        }, () => {
        });
    });
  }


  // contact seller for enquiry
  contactSeller(data, owner_id) {
    const body = {
      name: data.name,
      email: data.email,
      phone: data.phone,
      message: data.message,
      owner_id: owner_id
    };

    return new Promise((resolve, reject) => {
      this.http.post(environment.apiURL + '/SpaceOwnerContact', JSON.stringify(body), {
        headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' })
      })
        .subscribe(res => {
          resolve(res);
          // //console.log(JSON.stringify(res));
        }, (err) => {
          reject(err);
        }, () => {
        });
    });
  }


  // get similar spaces
  getSimilarSpaces(spaceID?: any) {
    const body = { space_id: spaceID };
    return new Promise((resolve, reject) => {
      this.http.post(environment.apiURL + '/SimilarSpace', JSON.stringify(body), {
        headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' })
      })
        .subscribe(res => {
          resolve(res);
          // //console.log(JSON.stringify(res));
        }, (err) => {
          reject(err);
        }, () => {
        });
    });
  }


  // wishlist toggle
  toggleWishlist(userID, spaceID) {
    const body = { user_id: userID, space_id: spaceID };
    return new Promise((resolve, reject) => {
      this.http.post(environment.apiURL + '/AddRemoveWishlist', JSON.stringify(body), {
        headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' })
      })
        .subscribe(res => {
          resolve(res);
          // //console.log(JSON.stringify(res));
        }, (err) => {
          reject(err);
        }, () => {
        });
    });
  }

  // get all wishlisted spaces
  getWishListedSpaces(userID) {
    const body = { user_id: userID };
    return new Promise((resolve, reject) => {
      this.http.post(environment.apiURL + '/MyWishlist', JSON.stringify(body), {
        headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' })
      })
        .subscribe(res => {
          resolve(res);
          // //console.log(JSON.stringify(res));
        }, (err) => {
          reject(err);
        }, () => {
        });
    });
  }

  // get spacetype with icon and bc images
  getAllcategories() {
    return new Promise((resolve, reject) => {
      this.http.post(environment.apiURL + '/getCategorySpace', 'xyz', {
        headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' })
      })
        .subscribe(res => {
          resolve(res);
          // //console.log(JSON.stringify(res));
        }, (err) => {
          reject(err);
        }, () => {
        });
    });

  }

  // explore or searchSpaces
  searchSpaces(data, offset) {
    //console.log(data,"searchSpace",data.maxVal);
    const body = {
      searchtext: data.searchText,
      spacetype: data.spaceType,
      state: data.inputState,
      city: data.inputCity,
      // min_price: data.price[0] || '0',
      // max_price: data.price[1] || '',
      min_price: data.minVal || '0',
      max_price: data.maxVal>=0 ?  data.maxVal:'',
      timebase: data.time_base,
      offset: offset,
      sort_by: data.sort_by,
      order: data.order
    };

    // //console.log(JSON.stringify(body));
    return new Promise((resolve, reject) => {
      this.http.post(environment.apiURL + '/SpaceSearchDetail', JSON.stringify(body), {
        headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' })
      })
        .subscribe(res => {
          resolve(res);
          // //console.log(JSON.stringify(res));
        }, (err) => {
          reject(err);
        }, () => {
        });
    });
  }

  // home page slider search
  searchSpaceList(searchWord) {
    const body = { search: searchWord };
    return new Promise((resolve, reject) => {
      this.http.post(environment.apiURL + '/ExploreSpaceSearch', JSON.stringify(body), {
        headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' })
      })
        .subscribe(res => {
          resolve(res);
          // //console.log(JSON.stringify(res));
        }, (err) => {
          reject(err);
        }, () => {
        });
    });
  }
  //book space
  book_space(data) {
    // //console.log(data);
    return new Promise((resolve, reject) => {
      this.http.post(environment.apiURL + '/bookingSpaces', JSON.stringify(data), {
        headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' })
      })
        .subscribe(res => {
          resolve(res);
          // //console.log(JSON.stringify(res));
        }, (err) => {
          reject(err);
        }, () => {
        });
    });
  }

  bookingSpaceMail(data){
    return new Promise((resolve, reject) => {
      this.http.post(environment.apiURL + '/bookingSpacesMails', JSON.stringify(data), {
        headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' })
      })
        .subscribe(res => {
          resolve(res);
          // //console.log(JSON.stringify(res));
        }, (err) => {
          reject(err);
        }, () => {
        });
    });
  }
  // get all bookings/reservations
  getBookings(userID: any, type) {
    const body = { user_id: userID, type: type };

    return new Promise((resolve, reject) => {
      this.http.post(environment.apiURL + '/getbookings', JSON.stringify(body), {
        headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' })
      })
        .subscribe(res => {
          resolve(res);
          // //console.log(JSON.stringify(res));
        }, (err) => {
          reject(err);
        }, () => {
        });
    });
  }
  // post feedback for booking
  postSpaceFeedback(user_id, space_id, booking_id, form_data) {
    const body = {
      user_id: user_id,
      booking_id: booking_id,
      space_id: space_id,
      rate: form_data.rating_stars,
      review: form_data.feedback,
    };
    //console.log(body);
    return new Promise((resolve, reject) => {
      this.http.post(environment.apiURL + '/SpaceReviews', JSON.stringify(body), {
        headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' })
      })
        .subscribe(res => {
          resolve(res);
          // //console.log(JSON.stringify(res));
        }, (err) => {
          reject(err);
        }, () => {
        });
    });
  }


  // get feedback of specific space booking
  getFeedback(booking_id, space_id, user_id) {
    const body = { user_id: user_id, space_id: space_id, booking_id: booking_id };
    //console.log(body);
    return new Promise((resolve, reject) => {
      this.http.post(environment.apiURL + '/GetOneSpaceReview', JSON.stringify(body), {
        headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' })
      })
        .subscribe(res => {
          resolve(res);
          // //console.log(JSON.stringify(res));
        }, (err) => {
          reject(err);
        }, () => {
        });
    });
  }


  // give rating to renter
  rateYourRenter(data) {
    return new Promise((resolve, reject) => {
      this.http.post(environment.apiURL + '/AddBuyerReview', JSON.stringify(data), {
        headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' })
      })
        .subscribe(res => {
          resolve(res);
          // //console.log(JSON.stringify(res));
        }, (err) => {
          reject(err);
        }, () => {
        });
    });
  }

  // get the Renter Rating for updation
  getRenterRating(data) {
    return new Promise((resolve, reject) => {
      this.http.post(environment.apiURL + '/ViewSingleBuyerReview', JSON.stringify(data), {
        headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' })
      })
        .subscribe(res => {
          resolve(res);
          // //console.log(JSON.stringify(res));
        }, (err) => {
          reject(err);
        }, () => {
        });
    });
  }


  // get all reviews of a space
  getAllReviews(space_id) {
    const body = { space_id: space_id };
    // //console.log(body);
    return new Promise((resolve, reject) => {
      this.http.post(environment.apiURL + '/GetReviewsBySpace', JSON.stringify(body), {
        headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' })
      })
        .subscribe(res => {
          resolve(res);
          // //console.log(JSON.stringify(res));
        }, (err) => {
          reject(err);
        }, () => {
        });
    });
  }

  // get Specific Review for header notification
  getSpecificReview(review_id) {
    const body = { review_id: review_id };
    return new Promise((resolve, reject) => {
      this.http.post(environment.apiURL + '/ReviewDetails', JSON.stringify(body), {
        headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' })
      })
        .subscribe(res => {
          resolve(res);
          // //console.log(JSON.stringify(res));
        }, (err) => {
          reject(err);
        }, () => {
        });
    });
  }

  // get all reviews of a renter
  getRenterRatings(data) {
    return new Promise((resolve, reject) => {
      this.http.post(environment.apiURL + '/GetBuyerReview', JSON.stringify(data), {
        headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' })
      })
        .subscribe(res => {
          resolve(res);
          // //console.log(JSON.stringify(res));
        }, (err) => {
          reject(err);
        }, () => {
        });
    });
  }

  // booking Approval from seller/owner
  booking_approval(body) {
    return new Promise((resolve, reject) => {
      this.http.post(environment.apiURL + '/ChangeBookingStatus', JSON.stringify(body), {
        headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' })
      })
        .subscribe(res => {
          resolve(res);
          // //console.log(JSON.stringify(res));
        }, (err) => {
          reject(err);
        }, () => {
        });
    });
  }

  // booking Approval for fre Space from seller/owner
  booking_approvalForFree(body) {
    return new Promise((resolve, reject) => {
      this.http.post(environment.apiURL + '/ChangeBookingStatusForFree', JSON.stringify(body), {
        headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' })
      })
        .subscribe(res => {
          resolve(res);
          // //console.log(JSON.stringify(res));
        }, (err) => {
          reject(err);
        }, () => {
        });
    });
  }

  // get Extra info on booking page like rate,reviews and similar spaces
  getExtraInfoOnBookingPage(data) {
    return new Promise((resolve, reject) => {
      this.http.post(environment.apiURL + '/SimilarSpaceBook', JSON.stringify(data), {
        headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' })
      })
        .subscribe(res => {
          resolve(res);
          // //console.log(JSON.stringify(res));
        }, (err) => {
          reject(err);
        }, () => {
        });
    });
  }
}
