import { Component, OnInit } from '@angular/core';
import { SpaceService, AuthenticationService, UserService, AppAlertService, DataService, PaymentService } from '../_services';
import { FormBuilder, FormGroup, Validators, FormControl, AbstractControl } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';
import { Http, Headers } from '@angular/http';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { NavigationExtras, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2'
import { ToastrService } from 'ngx-toastr';

// import * as Stripe from 'stripe';
@Component({
  selector: 'app-reservations',
  templateUrl: './reservations.component.html',
  styleUrls: ['./reservations.component.css']
})
export class ReservationsComponent implements OnInit {
  user: any;
  bookingList: any;
  paymentList: any;
  currentDate: any;

  spaceName: any;
  spaceID: any;
  booking_id: any;
  noReservations: any = true;
  picURL = environment.picURL;


  private modalRef: any;

  feedbackForm: FormGroup;
  customStripeForm: FormGroup;
  rating_stars: any = 0;

  public limit = 3;
  public slicei: any = 0;
  public slicea: any = this.limit;
  public page: any = 1;
  public lastpage: any;

  private stripeKey :any;
  private stripeImage = environment.stripeImage;
  private generatedToken: any;

  feedbackGiven = false;
  submitted=false;
  reviewSubmitted: any = false;
  appData: any;
  formProcess: boolean;
  message: string;
  booking_amount: any;
  stripBookingdata: any;
  constructor(private spacesService: SpaceService,
    private alert: AppAlertService,
    private dataService: DataService,
    private http: HttpClient,
    private modalService: NgbModal,
    private paymentService: PaymentService,
    private titleService: Title,
    private toast: ToastrService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private formBuilder: FormBuilder,
    private userService: UserService, ) {
    this.user = this.userService.getUserData() || [];

    this.feedbackForm = this.formBuilder.group({
      rating_stars: new FormControl('', Validators.required),
      // feedback: new FormControl('', Validators.compose([Validators.required, Validators.maxLength(500), Validators.minLength(50)])),
      feedback: new FormControl(''),
    });
  }

  ngOnInit() {
    this.appData = JSON.parse(window.localStorage.getItem('appData'));
    this.stripeKey=this.appData.stripe_clientid;
    this.titleService.setTitle('My Reservations :: '+this.appData.title);
    this.currentDate = new Date();
    this.getAllReservations();
  }

  getAllReservations() {
    this.spacesService.getBookings(this.user.id, 'buyer').then((response) => {
      if (response['status'] && response['data']) {
        this.bookingList = response['data'];
        this.paymentList = response['payments'];
        this.lastpage = Math.ceil(this.bookingList.length / this.limit);
        this.noReservations = false;
      } else if (!response['status'] && response['data']) {
        this.noReservations = true;
      } else {
        this.alert.error('Something went wrong. Try again later');
      }
    });
  }

  doTrim(INtext, INlength) {
    return this.dataService.doTrim(INtext, INlength);
  }

  getStatus(from_Date: Date, to_Date: Date) {
    let from_DATE = new Date(from_Date);
    let to_DATE = new Date(to_Date);
    if (this.currentDate < to_DATE && this.currentDate < from_DATE) {
      return ('upcoming');
    } else if (this.currentDate < to_DATE && this.currentDate >= from_DATE) {
      return ('ongoing');
    } else {
      return ('past');
    }
  }

  getTime(date) {
    const newDate = new Date(date);
    newDate.setMinutes(newDate.getMinutes() + 1);
    return newDate;
  }

  openmodal(content, spaceName, spaceID, bookingID) {
    this.modalRef = this.modalService.open(content);
    this.spaceName = spaceName;
    this.booking_id = bookingID;
    this.spaceID = spaceID;
    this.reviewSubmitted = false;

    this.spacesService.getFeedback(this.booking_id, this.spaceID, this.user.id).then(response => {
      if (response['status'] && response['data']) {
        this.feedbackForm.controls['feedback'].setValue(response['data']['review']);
        this.rating_stars = response['data']['rate'];
        this.feedbackGiven = true;
      } else if (!response['status'] && response['errors']) {
        this.feedbackForm.controls['feedback'].setValue(null);
        this.feedbackGiven = false;
      } else {
        this.alert.error('Something went wrong, Try later');
      }
    });
  }

  postFeedback() {
    this.spacesService.postSpaceFeedback(this.user.id, this.spaceID, this.booking_id, this.feedbackForm.value).then(data => {
      if (data['status'] && data['data']) {
        this.reviewSubmitted = true;
        this.getAllReservations();
        setTimeout(() => {
          this.modalRef.close();
        }, 2000);
      } else {
        this.alert.error('Something went wrong. Try later');
      }
    });
  }


  mLeft() {
    this.slicei = 0;
    this.slicea = this.limit;
    this.page = 1;
  }

  mRight() {
    if (this.bookingList.length > this.limit) {
      this.slicei = (this.lastpage * this.limit) - this.limit;
      this.slicea = this.bookingList.length;
      this.page = this.lastpage;
    }
  }

  fLeft() {
    if (this.page > 1) {
      this.page -= 1;
      this.slicei = (this.page - 1) * this.limit;
      this.slicea = ((this.page - 1) * this.limit) + this.limit;
    }
  }

  fRight() {
    if (this.lastpage > this.page) {
      this.page += 1;
      this.slicei = (this.page - 1) * this.limit;
      this.slicea = ((this.page - 1) * this.limit) + this.limit;
    }
  }

  goto(space_id) {
    const navigationExtras: NavigationExtras = { queryParams: { 'spaceID': space_id } };
    this.router.navigate(['space-detail'], navigationExtras);
  }

  step2_sendStripeToken(booking_data, stripe_token, booking_amount) {
    this.spinner.show();
    const body = {
      stripeToken: stripe_token,
      user_id: this.user.id,
      amount: booking_amount * 100,
      booking_id: booking_data.id
    };

    this.paymentService.makePayment(body).then(res => {
      this.spinner.hide();

      if (res['status']) {
        Swal.fire({
          type: 'success',
          title: 'Payment Successful',
          width: '400px',
          text: 'Your payment of $' + res['data']['amount'] / 100 + ' has been completed.',
          confirmButtonText: 'OK',
          confirmButtonColor: '#42bbae',
        });
        this.modalRef.close();
        this.toast.success('Your payment has been successful.', 'Success', { progressBar: true });
        setTimeout(() => {
          this.ngOnInit();
        }, 3000);

      } else if (!res['status']) {
        Swal.fire({
          type: 'error',
          title: 'Oops...',
          width: '400px',
          text: 'Your payment is failed. Kindly try again later',
          confirmButtonText: 'OK',
          confirmButtonColor: '#de783cfc',
        });
        this.toast.error('Your payment is failed. Kindly try again later', 'error');
      }
    });
  }
  openStripeModal(content,booking_data) {
    this.modalRef = this.modalService.open(content);
    this.stripBookingdata=booking_data;
    this.booking_amount = this.paymentList[booking_data.id].amount;
    var fees_per_hour = JSON.parse(localStorage.getItem('appData'))['fees_per_hour'];
    var extra = this.booking_amount * fees_per_hour / 100;
   this.booking_amount = this.booking_amount * 1 + extra;
   this.booking_amount = this.booking_amount.toFixed(2);
    this.customStripeForm = this.formBuilder.group({
      cardNumber: ['', [Validators.required,Validators.pattern('^[ 0-9]*$'), Validators.minLength(16)]], 
      expMonth: ['', [Validators.required]],
      expYear: ['', [Validators.required,Validators.minLength(4)]],     
      cvv: ['', [Validators.required, Validators.minLength(3)]],
      captcha:['',Validators.required],
    });
  } 
  pay(form) {
   
    if(!window['Stripe']) {
      this.toast.error('Stripe did not initialize properly.');
      return;
    }
     
    this.submitted = true;
    
    if (this.customStripeForm.invalid) { 
      this.toast.error('Invalid card detail!!');     
      return;
    }   
    this.formProcess = true;
    if(!window['Stripe']) {
      this.toast.error('Stripe did not initialize properly.');
      return;
    }
   
    (<any>window).Stripe.card.createToken({
      number: form.cardNumber,
      exp_month: form.expMonth,
      exp_year: form.expYear,
      cvc: form.cvc,
      currency: 'AUD',
     
    }, (status: number, response: any) => {
      this.submitted = false;
      this.formProcess = false;
      if (status === 200) {
        let token=response.id;
        this.step2_sendStripeToken(this.stripBookingdata,token, this.booking_amount);
       
      } else {
        this.toast.error(response.error.message);
      }
    });
  }

  // make payment
  // step1_initiatePayment(booking_data) {
  //   var booking_amount = this.paymentList[booking_data.id].amount;
  //   var fees_per_hour = JSON.parse(localStorage.getItem('appData'))['fees_per_hour'];
  //   var extra = booking_amount * fees_per_hour / 100;
  //   booking_amount = booking_amount * 1 + extra;
  //   booking_amount = booking_amount.toFixed(2);
    
  //   this.spinner.show();
  //   setTimeout(() => {
  //     this.spinner.hide();
  //   }, 3000);
  //   const handler = (<any>window).StripeCheckout.configure({
  //     key: this.stripeKey,
  //     image: 'assets/logo/stripe_logo.png',
  //     locale: 'auto',
  //     token: (token: any) => {       
  //       this.generatedToken = token;
  //       this.step2_sendStripeToken(booking_data, token, booking_amount);
  //     }
  //   });
  //   handler.open({
  //     name: 'Lonely Space',
  //     description: 'Please enter credit card details',
  //     currency: 'AUD',
  //     amount: booking_amount * 100
  //   });
  // }


}
