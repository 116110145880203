import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/_services';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-reward-section',
  templateUrl: './reward-section.component.html',
  styleUrls: ['./reward-section.component.css']
})
export class RewardSectionComponent implements OnInit {
  page:any;
  imgURL = environment.picURLSpaceType;
  constructor(public userService: UserService,) { }

  ngOnInit() {
    this.userService.getPost().then((response) => {
      //console.log(response);
      
      if (response['status']) {
        this.page = response['data'][0];
      }
    });
  }

  myStyle(space): object {
    if (space) {
      return {
        'background-image': 'url(' + this.imgURL + '/post/' + space + ')',
      };
    }
  }

}
