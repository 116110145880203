import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';

import { environment } from 'src/environments/environment';
import { SpaceService, DataService } from 'src/app/_services';


@Component({
  selector: 'app-featured-space',
  templateUrl: './featured-space.component.html',
  styleUrls: ['./featured-space.component.css']
})
export class FeaturedSpaceComponent implements OnInit {
  FeaturedSpaces: any;
  picURL = environment.picURL;
  constructor(private router: Router,
    private dataService: DataService,
    private spacesService: SpaceService,
    private route: ActivatedRoute) { }


  ngOnInit() {
    this.spacesService.getFeaturedSpaces().then((data) => {
      if (data['status']) {
        this.FeaturedSpaces = data['data'];
      }
    });
  }

  showSpace(spaceID) {
    //console.log(spaceID);
    const navigationExtras: NavigationExtras = {
      queryParams: { 'spaceID': spaceID }
    };
    this.router.navigate(['space-detail'], navigationExtras);
  }
}
