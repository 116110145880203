import { Component } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { UserService, AppAlertService, AuthenticationService } from './_services';
import { NgxSpinnerService } from 'ngx-spinner';
import { Title }     from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  appData: any;
  stripeKey: any;

  constructor(private router: Router,
    private alert: AppAlertService,
    private spinner: NgxSpinnerService,
    private userService: UserService,
    private auth: AuthenticationService,) {
    
      let user = JSON.parse(localStorage.getItem('user'));
      if(!user){
        localStorage.removeItem('user');
        localStorage.removeItem('profile_pic');
        localStorage.clear();
      }
    // get all app related data
    this.userService.getAppData().then((data: any) => {
      if (data['status']) {
        localStorage.setItem('appData', JSON.stringify(data['data']));
        this.auth.appData(JSON.stringify(data['data']));        
      } else { this.alert.error('Website is under maintenance!'); }

    }).catch(function (fallback) {
      console.log('error');
      console.log(fallback);
    });
   
    
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }
  ngOnInit(): void {
    this.loadStripe();
  }

  loadStripe() {
    this.appData = JSON.parse(window.localStorage.getItem('appData'));
    this.stripeKey=this.appData.stripe_clientid;
    if(!window.document.getElementById('stripe-custom-form-script')) {
      var s = window.document.createElement("script");
      s.id = "stripe-custom-form-script";
      s.type = "text/javascript";
      s.src = "https://js.stripe.com/v2/";
      s.onload = () => {
        window['Stripe'].setPublishableKey(this.stripeKey);
      }
       
      window.document.body.appendChild(s);
    }
  }

}
