import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, AbstractControl } from '@angular/forms';
import { UserService, AppAlertService, AuthenticationService } from 'src/app/_services';
import Swal from 'sweetalert2'
import { Router } from '@angular/router';

@Component({
  selector: 'app-subscribe-section',
  templateUrl: './subscribe-section.component.html',
  styleUrls: ['./subscribe-section.component.css']
})
export class SubscribeSectionComponent implements OnInit {
  public subscribeForm: FormGroup;
  sub_processing = false;
  sub_success = false;
  sub_failed = false;
  appData: any;

  constructor(public formBuilder: FormBuilder,
    private auth: AuthenticationService,
    private router: Router,
    public alert: AppAlertService,
    public userService: UserService) {
      auth.app_data.subscribe(output => {
        this.appData = JSON.parse(output);
      });
    this.subscribeForm = this.formBuilder.group({
      email: new FormControl('', Validators.compose([Validators.required,
      Validators.pattern('^[_a-z0-9]+(\.[_a-z0-9]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,4})$')]))
    });
  }

  ngOnInit() {
    this.appData = JSON.parse(window.localStorage.getItem('appData'));
  }
  subscribe(event) {
    this.sub_processing = true;
    this.userService.subscribeToMail(this.subscribeForm.value).then(data => {
      //console.log(data);
      this.sub_processing = false;
      if (data['status']) {
        this.sub_success = true;
        Swal.fire({
          type: 'success',
          title: 'Success',
          width:'400px',
          text: data['success'], 
          confirmButtonText: 'OK',
          confirmButtonColor: '#42bbae',
        })
        this.subscribeForm.reset();
      } else if (data['error'].split(':')[0]== 400 && data['status'] === false) {
        Swal.fire({
          //  type: 'error',
          title: 'Subscribed',
          // icon: 'warning',
          width:'400px',
          text:data['error'].split(':')[1],
          confirmButtonText: 'OK',
          confirmButtonColor: '#de783cfc',
        })
      } else {
        this.alert.error('Something went wrong');
      }
    });

  }

}
