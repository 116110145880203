import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute, ParamMap, NavigationExtras } from '@angular/router';
import { UserService, SpaceService, DataService, AppAlertService } from '../_services';
import { FormBuilder, FormGroup, Validators, FormControl, AbstractControl } from '@angular/forms';
import { NgbDate, NgbCalendar, NgbModal, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';

import { DateRange, DateRangePickerModule } from '@uiowa/date-range-picker';
import { element } from '@angular/core/src/render3/instructions';
import * as moment from 'moment';
import { environment } from 'src/environments/environment';
import { PaymentService } from '../_services/payment.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { Title } from '@angular/platform-browser';
@Component({
  selector: 'app-booking-page',
  templateUrl: './booking-page.component.html',
  styleUrls: ['./booking-page.component.css']
})
export class BookingPageComponent implements OnInit, OnDestroy {
  @ViewChild('booking_done') elementView: ElementRef;
  @ViewChild('booking_confirmation') elementView1: ElementRef;
  
  spaceID: any;
  spaceDetails: any;
  spaceNoAvail: any;
  spaceBookings: any;
  noday: any = [];
  nodate: any = [];
  user: any;
  datePickerForm: FormGroup;
  tmindate: any;
  tmaxdate: any;
  fmindate: any;
  fmaxdate: any;
  space_fdate: any;
  space_tdate: any;
  disabled: any = false;
  fdisabled: any = false;
  disabl_hours: any = [];
  hrar = [];
  err = '';
  succes = '';
  space_disable: any = [];
  fhours: any = [];
  availability:any; 
  
  thours: any = [];
  
  ftimeformt: any = { 'id': 0, 'value': 'AM' };
  ttimeformt: any = { 'id': 0, 'value': 'AM' };

  SimilarSpaces: any;
  ReviewData: any;
  picURL = environment.picURL;
  modalRef: any;
  time_left: any;
  isReadyToGo = false;

  stripe_token: any;
  booking_amount: any = 0;
  booking_stripe_amount: any = 0;
  FROM_DATE: any;
  FROM_TIME: any;
  TO_DATE: any;
  TO_TIME: any;
  spaceNoBookings: any;
  show_B_spinner = false;
  fees_per_hour: any;
  calculated_price_rate:any;
  appData: any;
  imgURL = environment.picURLSpaceType;
  availabilityTime: any;
  time_f: number;
  time_t: number;
  availabilityArr: any=[];
  constructor(private router: Router,
    private userService: UserService,
    private spacesService: SpaceService,
    private formBuilder: FormBuilder,
    private calendar: NgbCalendar,
    private modalService: NgbModal,
    private toast: ToastrService,
    private spinner: NgxSpinnerService,
    private paymentService: PaymentService,
    private ngbDateParserFormatter: NgbDateParserFormatter,
    private alert: AppAlertService,
    private titleService: Title,
    private dataService: DataService,
    private _route: ActivatedRoute) {
    this.user = this.userService.getUserData() || [];
    const spaceDetail = JSON.parse(localStorage.getItem('spaceDetails'));
    console.log(spaceDetail,"spaceDetail");
    
    this.spaceDetails = spaceDetail[0];
    //console.log(this.spaceDetails.id);
    //console.log(this.spacesService.bookStatus+'hii');
      if(this.spacesService.bookStatus == true){
        this.spacesService.bookStatus=false;
      }else{
        const navigationExtras: NavigationExtras = { queryParams: { 'spaceID': this.spaceDetails.id } };
        this.router.navigate(['space-detail'], navigationExtras);
      }  
    this.spaceNoAvail = spaceDetail[1];
    this.spaceBookings = spaceDetail[2];
    this.spaceNoBookings = spaceDetail[3];
    this.availability = spaceDetail[4];
    this.availabilityTime=spaceDetail[5];
    console.log('this.availability' ,this.availability, this.availabilityTime);
    Object.keys(this.availability).map((item: any) => {
      // this.availabilityArr.push([item + " : " + this.availability[item]])
      //console.log(data['available'] +'-hii-'+item+this.availability[item]);
      this.availabilityArr.push({item:item,value:this.availability[item]});
    });
    if (this.spaceNoAvail.length > 1) {
      this.nodate = this.spaceNoAvail[1];
      this.noday = this.spaceNoAvail[0];
    }
    if (this.spaceNoAvail.length == 1) {
      this.noday = this.spaceNoAvail[0];
    }
    for (let index = 0; index < 24; index++) {
      var i = (index < 10) ? '0' + index.toString() : index.toString()
      var j = (index < 10) ? '0' + index.toString() : index.toString()
      this.fhours.push({ id: index, value: i + ':00', 'disabled': false })
      this.thours.push({ id: index, value: j + ':00', 'disabled': false })
    }
    const fstr = this.spaceDetails.from_date.split('/');
    const tstr = this.spaceDetails.to_date.split('/');
       const fday = Number(fstr[0]);
       const fmonth = Number(fstr[1]) - 1;
       const fyear = Number(fstr[2]);
       const tday = Number(tstr[0]);
       const tmonth = Number(tstr[1]) - 1;
       const tyear = Number(tstr[2]);
    this.space_fdate = new Date(fyear, fmonth, fday);
    this.space_tdate = new Date(tyear, tmonth, tday);
    const today = new Date();
    console.log('date h '+this.space_tdate + '--'+ today + '--' +this.spaceDetails.to_date+ '--' +this.spaceDetails.from_date);
    
    if (this.space_tdate > today && this.availability != '') {
      if (this.space_fdate < today) {
        this.tmindate = { 'year': today.getFullYear(), 'month': today.getMonth() + 1, 'day': today.getDate() + 1 };
        this.fmindate = { 'year': today.getFullYear(), 'month': today.getMonth() + 1, 'day': today.getDate() + 1 };
      } else {
        this.tmindate = { 'year': this.space_fdate.getFullYear(), 'month': this.space_fdate.getMonth() + 1, 'day': this.space_fdate.getDate() }
        this.fmindate = { 'year': this.space_fdate.getFullYear(), 'month': this.space_fdate.getMonth() + 1, 'day': this.space_fdate.getDate() }
      }
      this.tmaxdate = { 'year': this.space_tdate.getFullYear(), 'month': this.space_tdate.getMonth() + 1, 'day': this.space_tdate.getDate() }
     const space_tdate = { 'year': this.space_tdate.getFullYear(), 'month': this.space_tdate.getMonth() + 1, 'day': this.space_tdate.getDate() }
     const tspace_date= { 'year': today.getFullYear()+1, 'month': today.getMonth() + 1, 'day': today.getDate()};
      // console.log(space_tdate,tspace_date);
      const h=new Date(space_tdate.year,space_tdate.month,space_tdate.day);
      const t=new Date(tspace_date.year,tspace_date.month,tspace_date.day)
     if(h >= t){
        this.fmaxdate=tspace_date;
       }else{
        this.fmaxdate=space_tdate;
       }
     console.log(this.fmaxdate,this.fmindate);
       
      var chk_fmndt = new Date(this.fmindate.day + '/' + this.fmindate.month + '/' + this.fmindate.year);
      var chk_fmxdt = new Date(this.fmaxdate.day + '/' + this.fmaxdate.month + '/' + this.fmaxdate.year);
     console.log('date h',chk_fmndt,chk_fmxdt); 
     if (chk_fmndt >= chk_fmxdt) { //console.log('date h 2');
        this.fmindate = this.fmaxdate;
        this.disabled = true;
        this.fdisabled = true;
      }

    } else { //console.log('date h 1');
      this.disabled = true;
      this.fdisabled = true;
      // this.err = 'Booking is  not available, All dates are booked';
    }
  }
  isDisabled = (date: NgbDate) => {
    var ddt = false;
    if (this.availabilityArr.length > 0) {
      let closeDate = null;
      let idx=this.availabilityArr.findIndex(f => {
           if(f.value=='Close'){
            closeDate=new Date(f.item);
            closeDate = { 'year': closeDate.getFullYear(), 'month': closeDate.getMonth() + 1, 'day': closeDate.getDate() };
           }
           if(closeDate && closeDate.year==date.year && closeDate.month==date.month && closeDate.day==date.day){
            ddt = true;
            let index = this.space_disable.findIndex(f => f.year == date.year && f.month == date.month && f.day == date.day);
            if (index < 0) {
              this.space_disable.push(date);
            }
           }
      })
      if (ddt) { return true; }
    }
    if(this.availabilityTime.length>0){
      var ft;
      for(let i=0; i<=6; i++){
        let indx=this.availabilityTime.findIndex(res=>res.day == i);        
         if(indx<0){
          let idx=this.noday.findIndex(f => f!=i);
            if(idx<0){
              this.noday.push({day:i});
            } 
         }    
      }
      
    }
    if (this.noday.length > 0) {
      var ft;

      this.noday.findIndex(f => {
        if (f.day == 0) {
          const dd = 7;
          ft = (dd == this.calendar.getWeekday(date));
        } else {
          ft = (f.day == this.calendar.getWeekday(date));
        }
        if (ft) {
          ddt = true;
          let index = this.space_disable.findIndex(f => f.year == date.year && f.month == date.month && f.day == date.day);
          if (index < 0) {
            this.space_disable.push(date);
          }

        }
      });
      if (ddt) { return true; }
    }
    if (this.nodate.length > 0) {
      var ft;
      this.nodate.sort((a, b) => a.date.localeCompare(b.date));
      this.nodate.findIndex(f => {
        var dd = (date.day < 10) ? '0' + date.day.toString() : date.day.toString();
        var mm = ((date.month) < 10) ? '0' + (date.month).toString() : (date.month).toString();

        if (f.date == date.year + "-" + mm + "-" + dd) {
          ft = true;
        }
        if (ft) {
          ddt = true;
          let index = this.space_disable.findIndex(f => f.year == date.year && f.month == date.month && f.day == date.day);
          if (index < 0) {
            this.space_disable.push(date);
          }
        }
      });
      if (ddt) { return true; }
    }

    if (this.spaceBookings.length > 0) {
      var ft;
      this.spaceBookings.sort((a, b) => a.from_date.localeCompare(b.from_date));
      this.hrar = [];
      this.spaceBookings.findIndex(f => {

        var dd = (date.day < 10) ? '0' + date.day.toString() : date.day.toString();
        var mm = ((date.month) < 10) ? '0' + (date.month).toString() : (date.month).toString();
        var bk_fdt = new Date(f.from_date);
        var bk_tdt = new Date(f.to_date);
        var bk_dt = new Date(date.year + "-" + mm + "-" + dd);
        if ((bk_fdt < bk_dt) && (bk_dt < bk_tdt)) {
          ft = true;
        }

        if (bk_fdt.getFullYear() == bk_dt.getFullYear() && bk_fdt.getMonth() == bk_dt.getMonth() && bk_dt.getDate() == bk_fdt.getDate()) {
          ft = false;
          if (bk_tdt.getFullYear() == bk_dt.getFullYear() && bk_tdt.getMonth() == bk_dt.getMonth() && bk_dt.getDate() == bk_tdt.getDate()) {
            this.hrar.push({ 'from': bk_fdt.getHours(), 'to': bk_tdt.getHours() });
            var m = bk_fdt.getMonth() + 1;
            this.disabl_hours['"' + bk_fdt.getDate() + '/' + m + '/' + bk_fdt.getFullYear() + '"'] = this.hrar;
          } else {
            this.hrar.push({ 'from': bk_fdt.getHours(), 'to': 23 });
            var m = bk_fdt.getMonth() + 1;
            this.disabl_hours['"' + bk_fdt.getDate() + '/' + m + '/' + bk_fdt.getFullYear() + '"'] = this.hrar;
          }
        }

        if (bk_tdt.getFullYear() == bk_dt.getFullYear() && bk_tdt.getMonth() == bk_dt.getMonth() && bk_dt.getDate() == bk_tdt.getDate()) {
          ft = false;


          if (bk_fdt.getFullYear() == bk_dt.getFullYear() && bk_fdt.getMonth() == bk_dt.getMonth() && bk_dt.getDate() == bk_fdt.getDate()) {
            // var hrar = {'from':bk_fdt.getHours(),'to':bk_tdt.getHours()};
            // var m = bk_fdt.getMonth()+1;
            // this.disabl_hours['"'+bk_fdt.getFullYear()+'/'+m+'/'+bk_fdt.getDate()+'"']=hrar;
          } else {
            this.hrar.push({ 'from': 0, 'to': bk_tdt.getHours() });
            var m = bk_tdt.getMonth() + 1;
            this.disabl_hours['"' + bk_tdt.getDate() + '/' + m + '/' + bk_tdt.getFullYear() + '"'] = this.hrar;
          }
        }

        if (ft) {
          ddt = true;
          let index = this.space_disable.findIndex(f => f.year == date.year && f.month == date.month && f.day == date.day);
          if (index < 0) {
            this.space_disable.push(date);
          }
        }
      });
      if (ddt) { return true; }
    }
    if (this.spaceNoBookings.length > 0) {
      var ft;
      // this.nodate.sort((a, b) => a.date.localeCompare(b.date));
      this.spaceNoBookings.findIndex(f => {
        var dd = (date.day < 10) ? '0' + date.day.toString() : date.day.toString();
        var mm = ((date.month) < 10) ? '0' + (date.month).toString() : (date.month).toString();
        // console.log('nobooking f',f);

        if (f == date.year + "-" + mm + "-" + dd) {
          ft = true;
        }
        if (ft) {
          ddt = true;
          let index = this.space_disable.findIndex(f => f.year == date.year && f.month == date.month && f.day == date.day);
          if (index < 0) {
            this.space_disable.push(date);
          }
        }
      });
      if (ddt) { return true; }
    }
    return false;
  };
  isDisabledt = (date: NgbDate) => {
    var ddt = false;
    if (this.noday.length > 0) {
      var ft;
      this.noday.findIndex(f => {
        if (f.day == 0) {
          const dd = 7;
          ft = (dd == this.calendar.getWeekday(date));
        } else {
          ft = (f.day == this.calendar.getWeekday(date));
        }
        if (ft) { ddt = true; }
      });
      if (ddt) { return true; }
    }
    if (this.nodate.length > 0) {
      var ft;

      this.nodate.findIndex(f => {
        var dd = (date.day < 10) ? '0' + date.day.toString() : date.day.toString();
        var mm = ((date.month) < 10) ? '0' + (date.month).toString() : (date.month).toString();
        if (f.date == dd + "-" + mm + "-" + date.year) {
          ft = true;
        }
        if (ft) { ddt = true; }
      });
      if (ddt) { return true; }
    }

    return false;
  };
  ngOnInit() {
    this.appData = JSON.parse(window.localStorage.getItem('appData'));
    this.titleService.setTitle('Booking :: '+this.appData.title);
    this._route.queryParams.subscribe(params => { this.spaceID = params['spaceID']; });
    this.datePickerForm = this.formBuilder.group({
      from_time: new FormControl('', Validators.compose([Validators.required])),
      to_time: new FormControl('', Validators.compose([Validators.required])),
      buyer_id: new FormControl(this.user.id),
      from_date: new FormControl('', Validators.required),
      ftimefrmt: new FormControl(0),
      ttimefrmt: new FormControl(0),
      seller_id: new FormControl(this.spaceDetails.owner_id),
      space_id: new FormControl(this.spaceDetails.id)
    });

    this.spacesService.getExtraInfoOnBookingPage({ space_id: this.spaceID }).then((result) => {
      console.log(result);
      if (result['status']) {
        this.SimilarSpaces = result['space'];
        this.ReviewData = result['data'];
      }
    });
  }
 
  myStyle(space): object {
    if(space){
      return {
        'background-image': 'url(' +this.imgURL+'/images/'+ space + ')',
      };
    }
  }
  ontimeChange(event: any) {
    var newtrgt = event.target.value;
    
    for (let index = 0; index < 24; index++) {
      this.thours[index].disabled = true;
      for (let index = this.time_f; index <= this.time_t; index++) {
        
        this.thours[index].disabled = false;
      }
    }

    if (this.disabl_hours['"' + this.tmindate.year + '/' + this.tmindate.month + '/' + this.tmindate.day + '"']) {
      var hrarr = this.disabl_hours['"' + this.tmindate.year + '/' + this.tmindate.month + '/' + this.tmindate.day + '"'];
      hrarr.forEach(element => {
        for (let index = element.from; index <= element.to; index++) {
          this.thours[index].disabled = true;
        }
      });

    }
    for (let index = 0; index <= newtrgt; index++) {
      this.thours[index].disabled = true;
    }
  }

  onfDateSelect(fdate: NgbDate) {
    this.tmindate = fdate;
    
    var date=new Date(fdate.month + '/' + fdate.day + '/' + fdate.year);
    const day= date.getDay();
    
    let id=this.availabilityTime.findIndex(res=>res.day==day);
    if(id>=0){
     const time_from = parseInt(this.availabilityTime[id].time_from.split(':')[0], 10);
     const time_to = parseInt(this.availabilityTime[id].time_to.split(':')[0], 10);
     if (time_from > time_to) {
      this.time_f = time_to;
      this.time_t = time_from;
      
    } else {
      this.time_f = time_from;
      this.time_t = time_to;
    }
    // console.log(date,this.time_f,this.time_t);
        for (let index = 0; index < 24; index++) {        
          this.fhours[index].disabled = true;
          this.thours[index].disabled = true; 
                  
          for (let index = this.time_f; index <= this.time_t; index++) {
            this.fhours[index].disabled = false;
            this.thours[index].disabled = false;
          }    
        }
              
    }
    

    if (this.disabl_hours['"' + fdate.day + '/' + fdate.month + '/' + fdate.year + '"']) {
      var hrarr = this.disabl_hours['"' + fdate.day + '/' + fdate.month + '/' + fdate.year + '"'];
      console.log(hrarr);
      
      hrarr.forEach(element => {
        for (let index = element.from; index <= element.to; index++) {
          this.fhours[index].disabled = true;
          this.thours[index].disabled = true;
        }
      });
    }


    var fdt = new Date(fdate.day + '/' + fdate.month + '/' + fdate.year);
    var dhr = this.spaceDetails.min_hrs == 0 ? this.spaceDetails.min_no_of_days : this.spaceDetails.min_hrs
    var newdate = new Date(fdt);
    var mindtar = [];
    if (this.spaceDetails.pricehr == 'day') {

      this.tmindate = { 'year': newdate.getFullYear(), 'month': newdate.getMonth() + 1, 'day': newdate.getDate() };
      for (let index = 0; index < parseInt(dhr); index++) {
        let i = this.space_disable.findIndex(f => f.day == newdate.getDate() && f.month == newdate.getMonth() + 1 && f.year == newdate.getFullYear());
        if (i >= 0) {
          mindtar.push(i);
        }
        newdate.setDate(newdate.getDate() + 1);
      }

    } else {
      newdate.setHours(newdate.getHours() + parseInt(dhr));
    }
    if (this.space_tdate < newdate) {
      this.disabled = true;
      this.err = 'please select any other date';
    } else {
      this.disabled = false;
      this.err = '';
      if (this.spaceDetails.pricehr == 'day') {
        newdate.setDate(newdate.getDate() - 1);
        this.tmindate = { 'year': newdate.getFullYear(), 'month': newdate.getMonth() + 1, 'day': newdate.getDate() };
      } else {
        this.tmindate = { 'year': newdate.getFullYear(), 'month': newdate.getMonth() + 1, 'day': newdate.getDate() };
      }

    }


    if (mindtar.length > 0) {
      this.disabled = true;
      this.err = 'please select any other date';
    } else {
      this.disabled = false;
      this.err = '';
      let index = this.space_disable.findIndex(f => {
        var fdt = new Date(f.day + '/' + f.month + '/' + f.year);
        if (fdt > newdate) {
          return true;
        }

      }
      );
      if (index >= 0) {
        this.tmaxdate = { 'year': this.space_disable[index].year, 'month': this.space_disable[index].month, 'day': this.space_disable[index].day };
      } else {
        this.tmaxdate = { 'year': this.space_tdate.getFullYear(), 'month': this.space_tdate.getMonth() + 1, 'day': this.space_tdate.getDate() }
      }
    }
  }

  // open modal after boking the space
  open(content) {
    this.modalRef = this.modalService.open(content,{ backdrop : "static"});
  }

  // navigate to my reservations
  gotoListing() {
    clearInterval(this.time_left);
    this.router.navigate(['user-dashboard/reservations']);
  }

  inputFromDate(e) {
    // console.log('hello');
    // console.log(e);
  }

  ngOnDestroy() {
    // localStorage.removeItem('spaceDetails');
  }


  viewReviews() {
    const navigationExtras: NavigationExtras = {
      queryParams: { 'spaceID': this.spaceID }
    };
    this.router.navigate(['space-reviews'], navigationExtras);
  }

  // view space in same page
  viewSpace(spaceID) {
    const navigationExtras: NavigationExtras = {
      queryParams: { 'spaceID': spaceID }
    };
    this.router.navigate(['space-detail'], navigationExtras);
  }

  calculatePrice(price) {
    this.fees_per_hour = JSON.parse(localStorage.getItem('appData'))['fees_per_hour'];
    var extra = price * this.fees_per_hour / 100;
    return  this.calculated_price_rate  = price * 1 + extra
  }


  goodtogo(event) {
    var f_date = this.datePickerForm.value.from_date;
    var t_date = this.datePickerForm.value.from_date;

    // open pop up for confirmation
    this.calcDiff(f_date, t_date);
    // this.open(this.elementView1);
  }


  private calcDiff(f_date, t_date) {
    this.FROM_DATE = this.ngbDateParserFormatter.format(f_date);
    this.TO_DATE = this.ngbDateParserFormatter.format(f_date);
    this.FROM_TIME = this.datePickerForm.get('from_time').value * 1;
    this.TO_TIME = this.datePickerForm.get('to_time').value * 1 ;

    const fromDate: Date = this.createDateFromNgbDate(f_date);
    const toDate: Date = this.createDateFromNgbDate(t_date);
    const daysDiff = Math.floor(Math.abs(<any>fromDate - <any>toDate) / (1000 * 60 * 60 * 24));
    const hoursDiff = (this.TO_TIME - this.FROM_TIME) == 0 ? 1 : this.TO_TIME - this.FROM_TIME;

    if (this.spaceDetails.min_no_of_days * 1 > 0 && this.spaceDetails.pricehr == 'day') {
      this.booking_amount = (daysDiff + 1) * this.spaceDetails.pricerate;
      // console.log(((daysDiff + 1) * hoursDiff));
      // console.log(((daysDiff + 1) * hoursDiff) * 24 >= (this.spaceDetails.min_no_of_days * 24));



      if ((daysDiff + 1) == this.spaceDetails.min_no_of_days * 1) {
        if (((daysDiff + 1) * hoursDiff) >= (this.spaceDetails.min_no_of_days * (daysDiff + 1) * 24)) {
          this.err = "";
          this.isReadyToGo = true;
        } else {
          this.alert.error('Minimum days to book : ' + this.spaceDetails.min_no_of_days);
          this.err = 'Minimum days to book : ' + this.spaceDetails.min_no_of_days;
          this.isReadyToGo = false;
        }
      } else if ((daysDiff + 1) > this.spaceDetails.min_no_of_days * 1) {
        if (((daysDiff + 1) * hoursDiff) >= (this.spaceDetails.min_no_of_days * (daysDiff + 1) * 24)) {
          this.err = "";
          this.isReadyToGo = true;
        } else {
          this.alert.error('Select valid timings');
          this.err = 'Select Valid Timings.';
          this.isReadyToGo = false;
        }
      }
    } else if (this.spaceDetails.min_hrs * 1 > 0 && this.spaceDetails.pricehr == 'hour') {
      this.booking_amount = (daysDiff + 1) * hoursDiff * this.calculated_price_rate;
      if (hoursDiff >= (this.spaceDetails.min_hrs * 1)) {
        this.err = "";
        this.isReadyToGo = true;
        this.open(this.elementView1);
      } else if (hoursDiff <= 0) {
        this.toast.error('Minimum hours to book : ' + this.spaceDetails.min_hrs, 'Invalid Timing');
        this.err = 'Minimum hours to book : ' + this.spaceDetails.min_hrs;
        this.isReadyToGo = false;
        this.booking_amount = 0;
      } else {
        this.alert.error('Minimum hours to book : ' + this.spaceDetails.min_hrs);
        this.err = 'Minimum hours to book : ' + this.spaceDetails.min_hrs;
        this.isReadyToGo = false;
      }

    }
    this.booking_stripe_amount = Math.round(this.booking_amount * (100));
  }

  private createDateFromNgbDate(ngbDate: NgbDate): Date {
    const date: Date = new Date(Date.UTC(ngbDate.year, ngbDate.month - 1, ngbDate.day));
    return date;
  }


  initiateBooking() {
    this.spinner.show();
    this.modalRef.close();                      //close payment confirmation pop-up
    var f_date = this.datePickerForm.value.from_date;
    var t_date = this.datePickerForm.value.from_date;

    var ftime = (this.datePickerForm.value.ftimefrmt == 1) ? (parseInt(this.datePickerForm.value.from_time) + 12) : this.datePickerForm.value.from_time;
    var ttime = (this.datePickerForm.value.ttimefrmt == 1) ? (parseInt(this.datePickerForm.value.to_time) + 12) : this.datePickerForm.value.to_time
     ttime=ttime-1;
    var data = {
      'buyer_id': this.datePickerForm.value.buyer_id,
      'seller_id': this.datePickerForm.value.seller_id,
      'space_id': this.datePickerForm.value.space_id,
      'from_date': f_date.year + '-' + f_date.month + '-' + f_date.day + ' ' + ftime + ':00',
      'to_date': t_date.year + '-' + t_date.month + '-' + t_date.day + ' ' + ttime + ':59',
      'from_time': ftime + ':00',
      'to_time': ttime + ':59',
    }

    this.spacesService.book_space(data).then((response) => {
      // console.log(response);
      this.spinner.hide();

      if (response['status'] == true) {
        this.datePickerForm.reset;
        this.err = '';
        let params= {
          'buyer_id': this.datePickerForm.value.buyer_id,
          'seller_id': this.datePickerForm.value.seller_id,
          'space_id': this.datePickerForm.value.space_id
        };
        this.spacesService.bookingSpaceMail(params);
        this.succes = 'Your Booking Request Has been sent to the Space Owner.';
        this.open(this.elementView);
        //this.toast.success('Your Booking Request Has been sent to the Space Owner. ', 'Success', {
      //    timeOut: 15000,tapToDismiss:true,progressBar: true });
        setTimeout(() => {
          this.modalRef.close();
          this.router.navigate(['user-dashboard/reservations']);
        }, 15000);

      } else {
        this.err = response['data'];
        this.succes = '';
      }
    });
  }
}




