import { Component, OnInit } from '@angular/core';
import { UserService, AppAlertService } from 'src/app/_services';

@Component({
  selector: 'app-customer-testimonial',
  templateUrl: './customer-testimonial.component.html',
  styleUrls: ['./customer-testimonial.component.css']
})
export class CustomerTestimonialComponent implements OnInit {
  TestimonialData: any;
  TestimonialPositionData: any;


  constructor(private userService: UserService,
    private alert: AppAlertService) { }

  ngOnInit() {
    this.userService.getTestimonials()
      .then((data) => {
        if (data['status']) {
          this.TestimonialData = data['data'];
          this.TestimonialPositionData = data['Positions'];
        } else {
          this.alert.error('Something');
        }
      });
  }

  // getTitle(id) {
  //   const rt = this.TestimonialPositionData.filter((x) => x.id === Number(id));
  //   return (rt[0].name);
  // }

}
