import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { AppAlertService, AuthenticationService } from '../_services';
import { PaymentService } from '../_services/payment.service';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2'
import { FileUploader, FileLikeObject } from 'ng2-file-upload';
import { v } from '@angular/core/src/render3';
@Component({
  selector: 'app-stripe',
  templateUrl: './stripe.component.html',
  styleUrls: ['./stripe.component.css'],
  providers: [{
    provide: STEPPER_GLOBAL_OPTIONS, useValue: { showError: true }
  }]
})
export class StripeComponent implements OnInit {
  user: any;
  profile_pic: any;
  admin_stripe_code = environment.admin_stripe_code;
  accountForm: FormGroup;
  public dt: any;
  isProcessing: boolean = false;
  isStripeConnected: boolean = false;
  showAc: boolean = false;
  appData: any;
  showCheck: boolean = false;
  front: File;
  back: File;
  imageSrc: string | ArrayBuffer;
  
  constructor(private titleService: Title,
    private alert: AppAlertService,
    private toastr: ToastrService,
    private auth: AuthenticationService,
    private paymentService: PaymentService,
    private _formBuilder: FormBuilder) {
    auth.user.subscribe(output => {
      this.user = JSON.parse(output);
      if (!!this.user) {
        this.isStripeConnected = (!!(this.user.stripe_connect_id)) ? true : false;
      }
    });
  }
  
  ngOnInit() {
    this.appData = JSON.parse(window.localStorage.getItem('appData'));
    this.titleService.setTitle('Account Setup :: ' + this.appData.title);
    this.profile_pic = localStorage.getItem('profile_pic') + '?=' + Math.random();
    this.user = this.auth.getUser();
    //console.log("my User is here", this.user, this.user.bank_connected);
    this.isStripeConnected = (!!(this.user.stripe_connect_id)) ? true : false;
    if (this.isStripeConnected || this.user.bank_connected == 1) {
      this.auth.getUserConnect(this.user.stripe_connect_id).then((data: any) => {
        //console.log(data);
        let detail = data['response'];
        let res = data.response.external_accounts.data[0];
        let dob1 = data.response.individual.dob;
        // //console.log(dob1.month+'/'+dob1.day+'/'+dob1.year);
        this.dt = new Date(dob1.month + '/' + dob1.day + '/' + dob1.year);
        //console.log(this.dt);

        var m = this.dt.getMonth() + 1;
        var d = this.dt.getDate();
        var y = this.dt.getFullYear();
        m = (m).toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false });
        d = (d).toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false });
        this.dt = y + '-' + m + '-' + d;
        //console.log(this.dt);
        this.front=detail['individual']['verification']['document']['front'];
        //console.log(this.front);
        
        let last4 = res['last4'];
        let toShow = "****" + last4.substr(last4.length - 2);
        this.accountForm = this._formBuilder.group({
          holder_name: [res['account_holder_name'], [Validators.required, Validators.minLength(2)]],
          first_name: [detail['individual']['first_name'], [Validators.required, Validators.minLength(2)]],
          last_name: [detail['individual']['last_name'], [Validators.required, Validators.minLength(2)]],
          email: [detail['individual']['email'], [Validators.required, Validators.pattern('^[_a-z0-9]+(\.[_a-z0-9]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,4})$')]],
          phone_number: [detail['individual']['phone'], [Validators.required, Validators.minLength(10), Validators.maxLength(13)]],
          account_no: [toShow, [Validators.required, Validators.minLength(5)]],
          routing_no: [res['routing_number'].replace(/\s/g, ""), [Validators.required, Validators.minLength(5)]],
          isUpdation: [1, [Validators.required]],
          connectid: [this.user.stripe_connect_id?1:0, [Validators.required]],
          dob: [this.dt, [Validators.required]],
          address: [detail['individual']['address']['line1'], [Validators.required]],
          postal_code: [detail['individual']['address']['postal_code'], [Validators.required]],
          country: [detail['individual']['address']['country'], [Validators.required]],
          state: [detail['individual']['address']['state'], [Validators.required]],
          city: [detail['individual']['address']['city'], [Validators.required]],
          // url: [detail['business_profile']['url'], [Validators.required]],
          // mcc: [detail['business_profile']['mcc'], [Validators.required]],
          // business_type: [detail['business_type'], [Validators.required]],
          edit: [],
          // holder_name1: [res['account_holder_name'], [Validators.minLength(2)]],
          // account_no1: [toShow, [Validators.minLength(5)]],
          //   routing_no1: [res['routing_number'].replace(/\s/g, ""), [Validators.minLength(5)]],
        });
      })
    } else if (this.isStripeConnected && this.user.bank_connected == 0) {
      this.accountForm = this._formBuilder.group({
        holder_name: ['', [Validators.required, Validators.minLength(2)]],
        first_name: ['', [Validators.required, Validators.minLength(2)]],
        last_name: ['', [Validators.required, Validators.minLength(2)]],
        email: ['', [Validators.required, Validators.pattern('^[_a-z0-9]+(\.[_a-z0-9]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,4})$')]],
        phone_number: ['+61', [Validators.required, Validators.minLength(10), Validators.maxLength(13)]],
        account_no: ['', [Validators.required, Validators.minLength(5)]],
        routing_no: ['', [Validators.required, Validators.minLength(5)]],
        isUpdation: [1, [Validators.required]],
        connectid: [this.user.stripe_connect_id?1:0, [Validators.required]],
        dob: ['', [Validators.required]],
        address: ['', [Validators.required]],
        postal_code: ['', [Validators.required]],
        country: ['Australia', [Validators.required]],
        state: ['', [Validators.required]],
        city: ['', [Validators.required]],
        // url: ['', [Validators.required]],
        // mcc: ['', [Validators.required]],
        // business_type: ['', [Validators.required]],
      });
    } else {
      this.accountForm = this._formBuilder.group({
        holder_name: ['', [Validators.required, Validators.minLength(2)]],
        first_name: ['', [Validators.required, Validators.minLength(2)]],
        last_name: ['', [Validators.required, Validators.minLength(2)]],
        email: ['', [Validators.required, Validators.pattern('^[_a-z0-9]+(\.[_a-z0-9]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,4})$')]],
        phone_number: ['+61', [Validators.required, Validators.minLength(10), Validators.maxLength(13)]],
        account_no: ['', [Validators.required, Validators.minLength(5)]],
        routing_no: ['', [Validators.required, Validators.minLength(5)]],
        isUpdation: [0, [Validators.required]],
        connectid: [0, [Validators.required]],
        dob: ['', [Validators.required]],
        address: ['', [Validators.required]],
        postal_code: ['', [Validators.required]],
        country: ['Australia', [Validators.required]],
        state: ['', [Validators.required]],
        city: ['', [Validators.required]],
        // url: ['', [Validators.required]],
        // mcc: ['', [Validators.required]],
        // business_type: ['', [Validators.required]],
      });
    }


  }
  showsAC(val) {
    this.showAc = !this.showAc;
    if (this.showAc) {
      this.accountForm.controls['edit'].setValue(1);
    } else {
      this.accountForm.controls['edit'].setValue(0);
    }

  }

  upload(event: any, val) {
    if (event.target.files.length === 0) {
      return;
    } else {
      const file: File = event.target.files[0];
      if (file.size < 10000000) {
        if (val == 'front') {
          this.front = file;
        } else {
          this.back = file;
        }       
      } else {
        this.alert.warn('Size of File should be smaller than 10MB');
      }

      const reader = new FileReader();
      reader.onload = e => this.imageSrc = reader.result;

      reader.readAsDataURL(file);
    }
  }

  getUpdateForm() {
    this.showCheck = true;
    this.isStripeConnected = !this.isStripeConnected;

  }
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }


  // save account details
  sendAccountDetails() {
    //console.log(this.accountForm.value.dob);
    if (this.showCheck) {
      if (this.showAc) {
        if (this.accountForm.value.account_no == "" || (this.accountForm.value.account_no.indexOf('*') != -1)) {
          this.alert.warn('Please provide valid Account Number ( without * )!');
          return false;
        } else {
          this.accountForm.controls['edit'].setValue(1);
        }
      } else {
        this.accountForm.controls['edit'].setValue(0);
      }
    } else {
      if ((this.accountForm.value.account_no.indexOf('*') != -1)) {
        this.alert.warn('Please provide valid Account Number ( without * )!');
        return false;
      }
    }


    var d1 = new Date(this.accountForm.value.dob);
    var d2 = new Date();
    if (((d2.getTime() - d1.getTime()) / 31536000000) < 18) {
      this.alert.warn('less then 18 year old');
      return
    }


    if (this.accountForm.invalid) {
      this.alert.warn('Please fill all the mandatory fields!');
      return
    } else {
      if (!this.front) {
        this.alert.warn('Profile Image is required.');
      }
      const data = this.accountForm.value;
      

      

      var formVal = new FormData();
      var dt = data.dob;
      dt = dt.split('-');
      dt = {
        day: parseInt(dt[2]),
        month: parseInt(dt[1]),
        year: parseInt(dt[0])
      };
      //console.log(data,dt);
      formVal.append('UserId', this.user.id);
      formVal.append('holderName', data.holder_name);
      formVal.append('routing', data.routing_no);
      formVal.append('account', data.account_no);
      formVal.append('isUpdation', data.isUpdation);
      formVal.append('connectid', data.connectid);
      formVal.append('country', data.country);
      formVal.append('type', 'custom');
      formVal.append('email', data.email);
      // formVal.append( 'requested_capabilities' , ['card_payments', 'transfers']);
      formVal.append('business_type', 'individual');
      formVal.append('url', 'Lonelyspaces.com');
      formVal.append('mcc', '6513');
      formVal.append('first_name', data.first_name);
      formVal.append('last_name', data.last_name);
      formVal.append('phone', data.phone_number);
      formVal.append('dob_day',dt.day);
      formVal.append('dob_month',dt.month);
      formVal.append('dob_year',dt.year);
      formVal.append('address', data.address);
      formVal.append('postal_code', data.postal_code);
      formVal.append('city', data.city);
      formVal.append('state', data.state);
      if(this.showCheck){
        formVal.append('editCheck', data.edit);
      }
      formVal.append('front', this.front);
      // formVal.append( 'back', this.back); 
    };
    this.isProcessing = true;
    // //console.log(this.accountForm.value);
    this.paymentService.saveAccountDetails(formVal).then(res => {
      //console.log(res);
      // return;

      this.isProcessing = false;

      if (res['status']) {

        this.alert.success('Account details saved.');
        Swal.fire({
          type: 'success',
          title: 'Successful',
          width: '400px',
          text: res['success'],
          confirmButtonText: 'OK',
          confirmButtonColor: '#42bbae',
        });
        this.showAc = false;
        this.accountForm.controls['edit'].setValue(0);
        this.isStripeConnected = true;
        localStorage.setItem('user', JSON.stringify(res['response']));
        this.auth.updateUser(JSON.stringify(res['response']));
      } else if (!res['status'] && res['error'] ) {
        this.alert.error(res['error']['message']);
        Swal.fire({
          type: 'error',
          title: 'Oops...',
          width: '400px',
          text: res['error']['message'],
          confirmButtonText: 'OK',
          confirmButtonColor: '#de783cfc',
        });
        if (res['successuser']) {
          localStorage.setItem('user', JSON.stringify(res['successuser']));
          this.auth.updateUser(JSON.stringify(res['successuser']));
          this.accountForm.controls['isUpdation'].setValue(true);
          this.accountForm.controls['connectid'].setValue(res['successuser']['stripe_connect_id']);
        }
      }else if(!res['status'] && res['errors']){
        this.alert.error(res['errors']['front']);
        Swal.fire({
          type: 'error',
          title: 'Oops...',
          width: '400px',
          text: res['errors']['front'],
          confirmButtonText: 'OK',
          confirmButtonColor: '#de783cfc',
        });
        if (res['successuser']) {
          localStorage.setItem('user', JSON.stringify(res['successuser']));
          this.auth.updateUser(JSON.stringify(res['successuser']));
          this.accountForm.controls['isUpdation'].setValue(true);
          this.accountForm.controls['connectid'].setValue(res['successuser']['stripe_connect_id']);
        }
      } else {
        this.alert.error('Something went wrong. Try later.');
      }
    });
  }
}
