import { Component, OnInit } from '@angular/core';
import { UserService, AppAlertService } from 'src/app/_services';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-our-philosophy',
  templateUrl: './our-philosophy.component.html',
  styleUrls: ['./our-philosophy.component.css']
})
export class OurPhilosophyComponent implements OnInit {

Phylosophydata:any;
imgURL = environment.picURLSpaceType;

  constructor(private userService: UserService,
    private alert: AppAlertService) { }

  ngOnInit() {
    this.userService.getPhylosophies()
      .then((data) => {
        if (data['status']) {
          // console.log(data,"data");
          
         this.Phylosophydata=data['data'];
        } else {
          this.alert.error('Something');
        }
      });
  }


}
