import { Component, OnInit } from '@angular/core';
import { UserService, AppAlertService } from 'src/app/_services';
import { NgxSpinnerService } from 'ngx-spinner';
import { Title } from '@angular/platform-browser';

@Component({
	selector: 'app-careers',
	templateUrl: './careers.component.html',
	styleUrls: [ './careers.component.css' ]
})
export class CareersComponent implements OnInit {
	pageContent: any;
	isDataLoaded = false;
	appData: any;

	constructor(
		private userService: UserService,
		private spinner: NgxSpinnerService,
		private titleService: Title,
		private appAlert: AppAlertService
	) {}

	ngOnInit() {
		this.appData = JSON.parse(window.localStorage.getItem('appData'));
		this.titleService.setTitle('Careers :: '+this.appData.title);
		this.spinner.show();
		this.userService.getPageContent(9).then((response) => {
			//console.log(response);
			this.spinner.hide();
			if (response['status']) {
				this.pageContent = response['data'];
				this.isDataLoaded = true;
			} else {
				this.isDataLoaded = false;
				this.appAlert.error('Something went wrong. Try later');
				//console.log();
			}
		});
	}

	myStyle(space): object {
		if(space){
			return {
				'background-image': 'url(' + space + ')'
			};
		}
	}
}
