import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, AbstractControl } from '@angular/forms';
import { Router } from '@angular/router';
import { AppAlertService, AuthenticationService, UserService } from '../_services';
import { Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent implements OnInit {
  contactUS_Form: FormGroup;
  appData: any;
  submitted: any = false;
  user: any;

  enquirySent: any = false;
  spinner: any = false;
  imgURL = environment.picURLSpaceType;

  icons=[
    {name: 'facebook',class:'fa fa-facebook'},
    {name: 'twitter',class:'fa fa-twitter'},
    {name: 'linkedin',class:'fa fa-linkedin'},
    {name: 'tiktok',class:'fab fa-tiktok'},
    {name: 'google',class:'fab fa-google'},
    {name: 'youtube',class:'fab fa-youtube'},
    {name: 'whatsapp',class:'fab fa-whatsapp'},
    {name: 'instagram',class:'fab fa-instagram'},
  ];
  iconN:any='d-none';
  iconData: any=[];

  constructor(private formBuilder: FormBuilder,
    private titleService: Title,
    private router: Router,
    private auth: AuthenticationService,
    private userService: UserService,
    private alert: AppAlertService) {
    auth.app_data.subscribe(output => {
      this.appData = JSON.parse(output);
    });
  }

  ngOnInit() {
   
    this.appData = JSON.parse(window.localStorage.getItem('appData'));
    this.titleService.setTitle('Contact Us :: '+this.appData.title);
    let icon_data=this.appData.slink.split(',');
    this.iconData=[];
    icon_data.forEach(item => {
      if(item){
        this.iconData.push(item);
      }
    })
    this.user = JSON.parse(localStorage.getItem('user')) || '';
    this.contactUS_Form = this.formBuilder.group({
      name: new FormControl(this.user.name, [Validators.compose([Validators.required, Validators.minLength(4)])]),
      email: new FormControl(this.user.email, [Validators.compose([Validators.required, Validators.email])]),
      phone: new FormControl(''),
      sub: new FormControl('', [Validators.compose([Validators.required, Validators.minLength(5)])]),
      message: new FormControl('', [Validators.compose([Validators.required, Validators.minLength(10)])]),
    });
    this.enquirySent = false;
  }
  myStyle(space): object {
    if(space){
      return {
        'background-image': 'url(' +this.imgURL+'/images/'+ space + ')',
      };
    }
  }
  getIcon(iname){
    const str=iname;
    this.iconN='';
    this.icons.forEach((ele)=>{
      if(str.match(ele.name)){
        this.iconN=ele.class;
      }      
    });
    return this.iconN;
  }
  getIconName(iname){
    const str=iname;
    this.iconN='';
    this.icons.forEach((ele)=>{
      if(str.match(ele.name)){
        this.iconN=ele.name;
      }      
    });
    return this.iconN;
  }
  get f() {
    return this.contactUS_Form.controls;
  } 

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode!=43) {
      return false;
    }
    return true;
  }

  sendEnquiry() {
    this.submitted = this.spinner = true;
    if (this.contactUS_Form.invalid) {
      this.spinner = false;
      //console.log(this.contactUS_Form);
      
      return;
    } else {
      this.userService.contact_us(this.contactUS_Form.value).then((result) => {
        //console.log(result);
        if (result['status']) {
          this.alert.success('Thanks for your message, expect to hear back from us soon!');
          this.contactUS_Form.reset();
          this.enquirySent = true;
          this.spinner = false;
        } else {
          this.alert.error('Something went wrong. Try later');
        }
      });
    }
  }
  openlink(link) {
    window.open(link, '_blank');
  }
}
