import { Component, OnInit } from '@angular/core';
import { DataService, SpaceService, AppAlertService, AuthenticationService, UserService } from 'src/app/_services';
import { environment } from 'src/environments/environment';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import { NavigationExtras, Router } from '@angular/router';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.css']
})
export class SliderComponent implements OnInit {
  public appData: any;
  spaceForm: FormGroup;
  SearchResults: any;
  imgURL = environment.picURLSpaceType;
  banner_img:any;
  AboutUsContent: any;


  constructor(private dataService: DataService,
    private formBuilder: FormBuilder,
    private alert: AppAlertService,
    private router: Router,
    private auth: AuthenticationService,
    public userService: UserService,
    private spacesService: SpaceService) {
    this.spaceForm = this.formBuilder.group({
      searchText: new FormControl('', Validators.required)
    });
    auth.app_data.subscribe(output => {
      this.appData = JSON.parse(output);
    });

  }

  ngOnInit() {
    this.userService.getAppData().then((data: any) => {
      // //console.log(JSON.stringify(data));
      if (data['status']) {
        localStorage.setItem('appData', JSON.stringify(data['data']));
        this.auth.appData(JSON.stringify(data['data']));
        this.appData = JSON.parse(window.localStorage.getItem('appData'));
        this.banner_img= this.imgURL+'/images/'+this.appData.banner_image;
        //console.log(this.banner_img);
      } else { this.alert.error('Website is under maintenance!'); }

    }).catch(function (fallback) {
      //console.log('error');
      //console.log(fallback);
    });
    
    
  }

  onSearchChange(searchValue: string) {
    if (searchValue.length > 3) {
      this.spacesService.searchSpaceList(searchValue).then((response) => {
        //console.log(response);
        if (response['status']) {
          this.SearchResults = response['data'];
        } else if (!response['status'] && response['data'] === '') {
          this.SearchResults = [{ title: 'No result Found' }];
        } else {
          this.alert.error('Something went wrong. Try later');
          this.SearchResults = '';
        }
      });
    } else {
      this.SearchResults = '';
    }
  }
  searchnow() {
    if (this.spaceForm.invalid) {
      return false;
    }
    //console.log(this.spaceForm.value);
    localStorage.setItem('searchtext', this.spaceForm.value.searchText);
    this.router.navigate(['explore-spaces']);
  }
  gotoSpace(spaceID) {
    const navigationExtras: NavigationExtras = {
      queryParams: { 'spaceID': spaceID }
    };
    this.router.navigate(['space-detail'], navigationExtras);

  }

  myStyle(space): object {
    if(space){
      return {
        'background-image': 'url(' + space + ')',
      };
    }
  }
}
