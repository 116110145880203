import { Component, OnInit } from '@angular/core';
import { UserService, AppAlertService } from 'src/app/_services';
import { NgxSpinnerService } from 'ngx-spinner';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.css']
})
export class AboutUsComponent implements OnInit {

  pageContent: any;
  isDataLoaded = false;
  bannerImage:any;
  appData: any;
  constructor(private userService: UserService,
    private spinner: NgxSpinnerService,
    private titleService: Title,
    private appAlert: AppAlertService) { }

  ngOnInit() {
    this.appData = JSON.parse(window.localStorage.getItem('appData'));
    this.titleService.setTitle('About Us :: '+this.appData.title);
    this.spinner.show();
    this.userService.getPageContent(13).then((response) => {
      //console.log(response);
      this.spinner.hide();
      if (response['status']) {
        this.pageContent = response['data'];
        this.isDataLoaded = true;
        this.bannerImage = this.pageContent['imageUrl'];
      } else {
        this.isDataLoaded = false;
        this.appAlert.error('Something went wrong. Try later');
      }
    });
  }
  
  myStyle(space): object {
    if(space){
      return {
        'background-image': 'url(' + space + ')',
      };
    }
  }
}
