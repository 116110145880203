import { Component, OnInit, OnDestroy } from '@angular/core';
import { AppRoutingModule } from '../app-routing.module';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import { Options, LabelType } from 'ng5-slider';

import { SpaceService, AppAlertService, DataService, AuthenticationService } from '../_services';
import { Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-explore-spaces',
  templateUrl: './explore-spaces.component.html',
  styleUrls: ['./explore-spaces.component.css']
})
export class ExploreSpacesComponent implements OnInit, OnDestroy {
  user: any;
  spaceForm: FormGroup;
  picURL = environment.picURL;

  catID: any;

  isGridView = true;
  noResultsFound = false;

  public SpaceTypes: any = [];
  public StateList: any = [];
  public CityList: any = [];

  offset: number = 1;
  public SearchResult: any = [];
  // price slider
  minValue: any = 0;
  maxValue: any =0;
  ceilValue: any =0;
  searchtext: any;
  options: Options;
  appData: any;
  imgURL = environment.picURLSpaceType;
   showAny:boolean=false;
  constructor(public _route: ActivatedRoute,
    public router: Router,
    public titleService: Title,
    public formBuilder: FormBuilder,

    public alert: AppAlertService,
    public spacesService: SpaceService,
    public dataService: DataService,
    private auth: AuthenticationService,
    public route: ActivatedRoute) {
    this.user = JSON.parse(localStorage.getItem('user')) || '';
    auth.app_data.subscribe(output => {
      this.appData = JSON.parse(output);
    });

    this.catID = localStorage.getItem('catID') || '';
    this.searchtext = localStorage.getItem('searchtext') || '';
    localStorage.removeItem('searchtext');
    // //console.log(this.catID);


    this.spaceForm = this.formBuilder.group({
      searchText: new FormControl(this.searchtext),
      spaceType: new FormControl(this.catID),
      inputCity: new FormControl(''),
      inputState: new FormControl(''),
      price: new FormControl(''),
      time_base: new FormControl('hour'),
      sort_by: new FormControl('id'),
      order: new FormControl('DESC'),
      minVal:new FormControl(''),
      maxVal:new FormControl(''),
    });
    this.searchSpace(this.offset, 0);
  }

  ngOnInit() {
    this.appData = JSON.parse(window.localStorage.getItem('appData'));
    const body = (this.user.id) ? { 'user_id': this.user.id } : { 'user_id': 0 };
    this.titleService.setTitle('Explore Spaces :: '+this.appData.title);
    this.spacesService.getCreateSpaceFormData(body).then((data) => {
      // //console.log(JSON.stringify(data));
      this.SpaceTypes = data['spacetype'];
      this.StateList = data['state'];
    }); 

    
    this.options = {
      floor: 0,
      ceil: this.ceilValue,
      translate: (value: number, label: LabelType): string => {
        switch (label) {
          case LabelType.Low:
            return '<b>Min:</b> $' + value;
          case LabelType.High:
            return '<b>Max:</b> $' + value;
          default:
            return '$' + value;
        }
      }
    };

  }

  myStyle(space): object {
    if(space){
      return {
        'background-image': 'url(' +this.imgURL+'/images/'+ space + ')',
      };
    }
  }

  onScroll() {
    this.offset = this.offset + 1;
    this.searchSpace(this.offset, 0);
  }

  get_sortedBY(ev) {
    if (ev.target.value === 'lTh') {
      this.spaceForm.controls['sort_by'].setValue('pricerate');
      this.spaceForm.controls['order'].setValue('ASC');
    } else if (ev.target.value === 'hTl') {
      this.spaceForm.controls['sort_by'].setValue('pricerate');
      this.spaceForm.controls['order'].setValue('DESC');
    } else {
      this.spaceForm.controls['sort_by'].setValue(ev.target.value);
      this.spaceForm.controls['order'].setValue('DESC');
    }
    this.searchSpace(1, 1);
  }


  // toggle_List_Grid(type) {
  //   this.isGridView = (type === 'grid') ? true : false;
  // }

  daysHourChange() {
    return;
    // const selected = this.spaceForm.controls['time_base'].value;
    // this.maxValue = (selected === 'day') ? 600 : 75;
    // this.ceilValue = (selected === 'day') ? 1000 : 100;
    // const options: Options = {
    //   floor: 0,
    //   ceil: this.ceilValue,
    //   translate: (value: number, label: LabelType): string => {
    //     switch (label) {
    //       case LabelType.Low:
    //         return '<b>Min:</b> $' + value;
    //       case LabelType.High:
    //         return '<b>Max:</b> $' + value;


    //       default:
    //         return '$' + value;
    //     }
    //   }
    // };
    // this.options = options;
    // //console.log(this.maxValue);
    // //console.log(this.options);
  }

  stateChange(state_id, state_name) {
    this.spaceForm.controls['inputCity'].setValue('');
    this.spacesService.getCityList(state_id).then(data => {
      this.CityList = (data['city'][0] !== undefined) ? data['city'] : [{ id: state_id, name: state_name }];
    });
  }
  valAny(){
    this.showAny=!this.showAny;
    if(this.showAny){
      this.spaceForm.controls['minVal'].setValue('');
      this.spaceForm.controls['maxVal'].setValue('');
    }
  }
  searchSpace(offset: any, frm: any) {
    if (frm === 1) {
      this.SearchResult = [];
      this.offset = 1;
      //console.log(this.spaceForm.value);
      
      // this.maxValue=this.spaceForm.value.spacetype;
    }
    //console.log('search space');
    
    localStorage.setItem('catID', this.spaceForm.get('spaceType').value);
    this.spacesService.searchSpaces(this.spaceForm.value, offset).then(data => {
      // //console.log(data);
      if (data['status']) {
        this.SearchResult = [...this.SearchResult, ...data['data']];
        if(this.SearchResult.length){
          if (frm === 0) {
            this.maxValue = data['mprice'];
          }
         
          this.ceilValue=parseInt(data['mprice']); 
          //console.log(this.maxValue,this.ceilValue);
              this.options = {
                floor: 0,
                ceil: this.ceilValue,
                translate: (value: number, label: LabelType): string => {
                  switch (label) {
                    case LabelType.Low:
                      return '<b>Min:</b> $' + value;
                    case LabelType.High:
                      return '<b>Max:</b> $' + value;
                    default:
                      return '$' + value;
                  }
                }
              };
          
        }
        // this.SearchResult = data['data'];
        // //console.log(this.SearchResult);
        this.noResultsFound = false;
      } else if (!data['status'] && data['data'] === '' && this.SearchResult.length == 0) {
        this.noResultsFound = true;
        this.SearchResult = [];
      }
     
    });
  }

  fetchDeatils(space_id) {
    const navigationExtras: NavigationExtras = { queryParams: { 'spaceID': space_id } };
    this.router.navigate(['space-detail'], navigationExtras);
  }

  ngOnDestroy() {
    localStorage.removeItem('catID');
  }
}
