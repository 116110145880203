// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // apiURL: 'https://lonelyspaces.com/admin/api',     // api
  // picURLSpaceType: 'https://lonelyspaces.com/admin',  // spacetype images
  // picURL: 'https://lonelyspaces.com/admin/SpaceImages',  // space images
  // profilePicURL: 'https://lonelyspaces.com/admin/profile_images',   //profile images
  apiURL: 'https://lonelyspaces.com/admin/public/api',     // api
  picURLSpaceType: 'https://lonelyspaces.com/admin/public',  // spacetype images
  picURL: 'https://lonelyspaces.com/admin/public/SpaceImages',  // space images
  profilePicURL: 'https://lonelyspaces.com/admin/public/profile_images',   //profile images
  googleMapURL: 'https://www.google.com/maps/embed/v1/place?q=',       //google map link
  // googleMapAPIKey: 'AIzaSyABA5OJGDAxnNac8ukDnnweOuUlqaJvwdY',          // google map api rv
  googleMapAPIKey: 'AIzaSyCJ61sEkedoqfBHIlw0y8dh6cJnCgZTWPY',          // google map api vik
  stripeKey: 'pk_live_51EL2bZG5vteF2TmOlzGgHLlGdJambyHAJKxIaaJeTQnIOXzDBDVm3uDgTtNbRtoWheg1e152SQWyvi7dSLIxfte300lliyhBCH', 
  // stripeKey: 'pk_test_GLhQ2I5yBNhE9jJy5yFqF0SN00QNaQqJcL',                        //stripe key
  stripeImage: 'https://lonelyspaces.com/admin/lonelyes/favicon.ico',        // stripe header image
  admin_stripe_code: 'ca_EoPKxAY1cWExtVYpk9rO8ADmgevPJUlc',
  authToken: btoa('admin:admin'),                                     // http authenicator
  secretKey: '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'
};
// http://visionvivante.com/lonely_spaces/public
/*
 * In development mode, for easier debugging, you can ignore zone related error
 * stack frames such as `zone.run`/`zoneDelegate.invokeTask` by importing the
 * below file. Don't forget to comment it out in production mode
 * because it will have a performance impact when errors are thrown
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
