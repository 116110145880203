import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { DataService, SpaceService, AppAlertService } from 'src/app/_services';
import { Router, NavigationExtras } from '@angular/router';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2'

@Component({
  selector: 'app-space-review-before-submission',
  templateUrl: './space-review-before-submission.component.html',
  styleUrls: ['./space-review-before-submission.component.css'] 
})
export class SpaceReviewBeforeSubmissionComponent implements OnInit {
  public user: any;
  public createSpaceAllData: any;
  public otherInfo: any;
  public createSpaceData: any;
  public createSpaceFiles: any;
  public createSpaceDay: any;
  public createSpaceDate: any;

  public SpaceTypes: any = [];
  public CapacityList: any = [];
  public AmenityList: any = [];
  public CanPolicyList: any = [];
  public StateList: any = [];
  public CityList: any = [];
  picURL = environment.picURL;
  public storeCheckedAmenities: any = [];

  nodate: any = [];
  noday: any = [];
  spaceTypeName: any;
  spaceCapacity: any;
  stateName: any;
  cityName: any;
  canPolicyDetail: any;
  IncomingAmenityIDList: any;
  modify_route: any;
  inComingFiles: any = [];

  OtherAmenityList: any = [];
  show_publish_spinner: any = false;
  public IncomingAmenityNamesListArr: any = [];
  public IncomingAmenityNamesListStr: any = '';
  public uploadFiles: any = [];
  public showOtherAmenity = false;
  public show_save_spinner = false;
  public show_submit_spinner = false;

  constructor(public dataService: DataService,
    public alert: AppAlertService,
    public location: Location,
    public router: Router,
    public toast: ToastrService,
    public spacesService: SpaceService) {
    this.user = JSON.parse(localStorage.getItem('user'));
    this.modify_route = localStorage.getItem('modify_route');
    this.createSpaceAllData = this.dataService.getDataStorage();
    this.createSpaceData = this.createSpaceAllData[0];
    //console.log(this.createSpaceData,"createSpace");
    
    this.createSpaceFiles = this.createSpaceAllData[1];
    this.createSpaceDay = this.createSpaceAllData[4];
    this.createSpaceDate = this.createSpaceAllData[3];
    this.nodate = this.createSpaceDate;
    this.noday = this.createSpaceDay;
    //console.log(this.nodate,this.noday);
    
    this.inComingFiles = this.createSpaceAllData[2];
    const body = { 'user_id': this.user.id };
    this.spacesService.getCreateSpaceFormData(body).then((data) => {
      this.otherInfo = data;
      this.SpaceTypes = data['spacetype'];
      this.CapacityList = data['capacityspace'];
      this.AmenityList = data['amenities'];
      this.CanPolicyList = data['cancel_policy'];
      this.StateList = data['state'];
      this.getinfo();
      this.getAmenitiesList();
      this.getCityName();

    });
  }
  ngOnInit() {
  }
  getinfo() {

    const rt1 = this.SpaceTypes.filter((x) => x.id === Number(this.createSpaceData.spaceType));
    this.spaceTypeName = rt1[0].name;

    const rt2 = this.CapacityList.filter((x) => x.id === Number(this.createSpaceData.spaceCapacity));
    this.spaceCapacity = rt2[0].name;

    const rt3 = this.StateList.filter((x) => x.id === Number(this.createSpaceData.inputState));
    this.stateName = rt3[0].name;

    const rt4 = this.CanPolicyList.filter((x) => x.id === Number(this.createSpaceData.cancel_policy));
    this.canPolicyDetail = rt4[0].name;

  }
  getCityName() {
    this.spacesService.getCityList(this.createSpaceData.inputState).then(data => {
      if (data['city'][0] !== undefined) {
        this.CityList = data['city'];
        const rt5 = this.CityList.filter((x) => x.id === Number(this.createSpaceData.inputCity));
        this.cityName = rt5[0].name;
      } else {
        this.cityName = this.stateName;
      }
    });
  }
  getAmenitiesList() {
    this.IncomingAmenityIDList = this.createSpaceData.amenity.split(',');
    for (const item of this.IncomingAmenityIDList) {
      const rt = this.AmenityList.filter((x) => x.id === Number(item));
      this.IncomingAmenityNamesListArr.push(rt[0].amenities_name);
    }
    this.showOtherAmenity = (this.createSpaceData['otherAmenities'].length > 0) ? true : false;
    this.IncomingAmenityNamesListArr = this.IncomingAmenityNamesListArr.filter(item => item !== 'Other Amenities');
    this.IncomingAmenityNamesListStr = this.IncomingAmenityNamesListArr.toString();
  }
  // go back
  goback() {
    localStorage.setItem('setpreview', '1');
    if (this.modify_route === '1') {
      const navigationExtras: NavigationExtras = { queryParams: { 'spaceID': this.createSpaceData.spaceId } };
      this.router.navigate(['user-dashboard/modify-space'], navigationExtras);
    } else {
      this.router.navigate(['user-dashboard/create-space']);
    }
  }
  // After create space
  saveNewAd(): void {
    this.show_save_spinner = true;
    this.spacesService.createSpace(this.createSpaceData, this.createSpaceFiles, 0, this.createSpaceDate,this.createSpaceDay,).then((data: any) => {
      if (data['success'] === 'space created successfully') {
        this.show_submit_spinner = false;
        this.alert.success('Space Saved!');
        this.router.navigate(['user-dashboard/seller-space-listing']);
      } else {
        this.show_submit_spinner = false;
        this.alert.error('Something went Wrong!');
      }
    });
  }
  // After create space
  publishNewAd(): void {
    //console.log(this.createSpaceData.free);
    
    if (((this.user.stripe_connect_id==null) && this.createSpaceData.free) || ((this.user.stripe_connect_id) && this.createSpaceData.free) || ((this.user.stripe_connect_id) && !this.createSpaceData.free)) {
      this.spacesService.createSpace(this.createSpaceData, this.createSpaceFiles, 1, this.createSpaceDate,this.createSpaceDay,).then((data: any) => {
        if (data['success'] === 'space created successfully') {
          this.toast.success('Your ad is live now!', 'Success');
          this.router.navigate(['user-dashboard/seller-space-listing']);
        } else {
          this.alert.error('Something went Wrong!');
        }
      });
    } else {
      this.show_submit_spinner = true;
      this.spacesService.createSpace(this.createSpaceData, this.createSpaceFiles, 0, this.createSpaceDate,this.createSpaceDay,).then((data: any) => {
        if (data['success'] === 'space created successfully') {
          this.show_submit_spinner = false;

          Swal.fire({
            title: 'Success',
            text: "Ad is created successfully but not live yet. Please connect with stripe to make ad live and receive payment.",
            type: 'success',
            showCancelButton: true,
            cancelButtonColor: '#f39968',
            cancelButtonText: 'Go to my ads listing',
            confirmButtonText: 'Connect with stripe',
            confirmButtonColor: '#4abfb3',
            backdrop: false
          }).then((result) => {
            if (result.value) {
              this.router.navigate(['/user-dashboard/stripe-setup']);
            } else {
              this.router.navigate(['user-dashboard/seller-space-listing']);
            }
          })

        } else {
          this.show_submit_spinner = false;
          this.alert.success('Something went Wrong!');
        }
      });
    }
  }
  // modify spaces
  onSubmit(publish): void {
    //console.log(publish);
    // (!!(this.user.stripe_connect_id)) &&
    if ( publish === '1') {
      this.show_publish_spinner = (publish === 1) ? true : false;
      this.show_save_spinner = (publish === 0) ? true : false;
      this.createSpaceData.publish = publish;
      if (((this.user.stripe_connect_id == null) && this.createSpaceData.free) || ((this.user.stripe_connect_id) && this.createSpaceData.free) || ((this.user.stripe_connect_id) && !this.createSpaceData.free)) {
        this.spacesService.modifySpace(this.createSpaceData, this.inComingFiles, this.createSpaceFiles,this.createSpaceDate,this.createSpaceDay,).then((data) => {
          if (data['status']) {
            this.show_publish_spinner = this.show_save_spinner = false;
            // //console.log(this.createSpaceData);
            // this.createSpaceData=0;

            this.router.navigate(['user-dashboard/seller-space-listing']);
            this.alert.success('Space updated successfully!');

          } else if (!data['status'] && data['errors'].picture) {
            this.show_publish_spinner = this.show_save_spinner = false;
            this.alert.error('Upload atleast 1 Picture!');
          } else {
            this.show_publish_spinner = this.show_save_spinner = false;
            this.alert.error('Something went wrong!');
          }
        });
      } else {
        this.alert.error('Please connect with stripe to make ad live.');
        this.router.navigate(['/user-dashboard/stripe-setup']);
      }
    } else{
      this.show_publish_spinner = (publish === 1) ? true : false;
      this.show_save_spinner = (publish === 0) ? true : false;
      this.createSpaceData.publish = publish;
      this.spacesService.modifySpace(this.createSpaceData, this.inComingFiles, this.createSpaceFiles, this.createSpaceDate,this.createSpaceDay,).then((data) => {
        if (data['status']) {
          this.show_publish_spinner = this.show_save_spinner = false;
          this.router.navigate(['user-dashboard/seller-space-listing']);
          this.alert.success('Space saved successfully!');
        } else if (!data['status'] && data['errors'].picture) {
          this.show_publish_spinner = this.show_save_spinner = false;
          this.alert.error('Upload atleast 1 Picture!');
        } else {
          this.show_publish_spinner = this.show_save_spinner = false;
          this.alert.error('Something went wrong!');
        }
      });
    }
    // else {
    //   this.alert.error('Please connect with stripe to make ad live.');
    //   this.router.navigate(['/user-dashboard/stripe-setup']);
    // }
  }
}
