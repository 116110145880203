import { Injectable, OnInit } from '@angular/core';
import { AlertsService } from 'angular-alert-module';
import { ToastrService } from 'ngx-toastr';


@Injectable({
  providedIn: 'root'
})
export class AppAlertService {

  constructor(private toastr: ToastrService) { }

  success(msg: any) {
    this.clear();
    this.toastr.success('', msg, {
      timeOut: 15000,tapToDismiss:true
    });
  }

  warn(msg: any) {
    this.clear();
    this.toastr.warning('', msg, {
      timeOut: 15000,tapToDismiss:true
    });
  }

  error(msg: any) {
    this.clear();
    this.toastr.error('', msg, {
      timeOut: 15000,tapToDismiss:true
    });
  }

  clear() {
    this.toastr.clear();
  }
}
