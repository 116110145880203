import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/_services';
import { Router } from '@angular/router';

@Component({
  selector: 'app-rent-section',
  templateUrl: './rent-section.component.html',
  styleUrls: ['./rent-section.component.css']
})
export class RentSectionComponent implements OnInit {

  appData: any;

  constructor(private auth: AuthenticationService,
    private router: Router) {
    auth.app_data.subscribe(output => {
      this.appData = JSON.parse(output);
    });
   }

  ngOnInit() {
    this.appData = JSON.parse(window.localStorage.getItem('appData'));
  }

}
