
import { Component, ViewChild, OnInit, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { AuthenticationService } from 'src/app/_services';
import { Router } from '@angular/router';
declare var $: any;


@Component({
  selector: 'app-copy-right',
  templateUrl: './copy-right.component.html',
  styleUrls: ['./copy-right.component.css']
})


export class CopyRightComponent implements OnInit , AfterViewInit {
  @ViewChild('sealRef') sealRef;
  appData: any;

  constructor(private auth: AuthenticationService,
    private router: Router) { 
    auth.app_data.subscribe(output => {
      this.appData = JSON.parse(output);
    });
  }

  ngOnInit() {
    this.appData = JSON.parse(window.localStorage.getItem('appData'));
  }
  ngAfterViewInit(){
    var iframe = document.createElement('iframe');
        iframe.width = '100'; 
        iframe.height = '40';
        iframe.frameBorder = '0';
        iframe.scrolling = "no";
        iframe.id = "myFrame";
        iframe.src = 'about:blank';
        var content = '<span id="siteseal">'+this.appData.go_daddy_link+'</span>'
        this.sealRef.nativeElement.appendChild(iframe);        
        iframe.contentWindow.document.open('text/html', 'replace');
        iframe.contentWindow.document.write(content);
        iframe.contentWindow.document.close();
        setTimeout(()=>{
          $('#myFrame').contents().find("img").css("height","25px");
          $('#myRef').css("display","block");
        },1000)
        $('#myFrame').contents().find("body").css("margin","unset");
  }
}
