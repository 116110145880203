import { Component, OnInit } from '@angular/core';
import { AppRoutingModule } from '../app-routing.module';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { UserService, SpaceService, AppAlertService, DataService } from '../_services';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-watch-list',
  templateUrl: './watch-list.component.html',
  styleUrls: ['./watch-list.component.css']
})
export class WatchListComponent implements OnInit {
  public WatchList = [];
  user: any;
  picURL = environment.picURL;
  public WatchList_const = [];
  SpaceTypeNames: any;
  public limit = 4;
  public slicei: any = 0;
  public slicea: any = this.limit;
  public page: any = 1;
  public lastpage: any;
  public loading:boolean;
  private data;
  appData: any;

  constructor(private router: Router,
    private userService: UserService,
    private alert: AppAlertService,
    private dataService: DataService,
    private spacesService: SpaceService,
    private route: ActivatedRoute) { }

  ngOnInit() {
    this.user = this.userService.getUserData();
    this.appData = JSON.parse(window.localStorage.getItem('appData'));
    this.userService.setPageTitle('My Watchlist :: '+  this.appData.title);
    this.getWishlistSpaces();
  }

  getWishlistSpaces() {
    this.loading=true;
    this.spacesService.getWishListedSpaces(this.user.id).then(data => {
      //console.log(data);
      this.data = data;
      this.loading = false;
      if (this.data.status && this.data.data.length) {
        this.WatchList_const = this.WatchList = this.data.data;
        // //console.log('this.WatchList',this.WatchList);
        
        this.lastpage = Math.floor(this.WatchList.length / this.limit);
      } else if (!this.data.data.length && this.data.status === false) {
        this.WatchList_const = this.WatchList = [];
      } else {
        this.alert.error('Something went wrong. Try later.');
      }
    });
  }
  fetchDeatils(spaceID) {
    const navigationExtras: NavigationExtras = {
      queryParams: { 'spaceID': spaceID }
    };
    this.router.navigate(['space-detail'], navigationExtras);
  }

  removeFromWishList(spaceID) {
    this.spacesService.toggleWishlist(this.user.id, spaceID).then(data => {
      //console.log(data);
      if (data['status']) {
        this.getWishlistSpaces();
        this.alert.error('Removed from watchlist.');
      } else {
        this.alert.error('Something went wrong. Try later');
      }
    });
  }

  mLeft() {
    this.slicei = 0;
    this.slicea = this.limit;
    this.page = 1;
  }

  mRight() {
    if (this.WatchList.length > this.limit) {
      this.slicei = (this.lastpage * this.limit) - this.limit;
      this.slicea = this.WatchList.length;
      this.page = this.lastpage;
    }
  }

  fLeft() {
    if (this.page > 1) {
      this.page -= 1;
      this.slicei = (this.page - 1) * this.limit;
      this.slicea = ((this.page - 1) * this.limit) + this.limit;
    }
  }

  fRight() {
    if (this.lastpage > this.page) {
      this.page += 1;
      this.slicei = (this.page - 1) * this.limit;
      this.slicea = ((this.page - 1) * this.limit) + this.limit;
    }
  }










}


