import { Component, ViewChild, OnInit, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { AppRoutingModule } from '../app-routing.module';
import { Router, ActivatedRoute, NavigationEnd, Event, NavigationExtras, ActivationEnd, RouterEvent } from '@angular/router';
import { filter } from 'rxjs/operators';
import { AuthenticationService, AppAlertService, MessagingService, UserService, DataService, SpaceService } from '../_services';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';
declare var $: any;
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, AfterViewInit {
  // @Output() msg_count: EventEmitter<any> = new EventEmitter();


  public currentUser: any;
  public profile_pic: any;
  public showdp: any = false;
  public appData: any;


  public isUserLoggedIn: any;
  public apiBODY: any;
  public unread_msg_count: any = 0;
  public unread_notification_count: any = 0;
  public NotificationList: any = [];
  public Unpublished: any = [];
  public modalRef: any;
  public feedbackData: any;
  notificationUpdated = 0;
  img_url:any=environment.profilePicURL;
  icons=[
    {name: 'facebook',class:'fa fa-facebook'},
    {name: 'twitter',class:'fa fa-twitter'},
    {name: 'linkedin',class:'fa fa-linkedin'},
    {name: 'tiktok',class:'fab fa-tiktok'},
    {name: 'google',class:'fab fa-google'},
    {name: 'youtube',class:'fab fa-youtube'},
    {name: 'whatsapp',class:'fab fa-whatsapp'},
    {name: 'instagram',class:'fab fa-instagram'},
  ];
  iconN:any='d-none';
  @ViewChild('sealRef') sealRef;
  dummy: string;
  constructor(private router: Router,
    public route: ActivatedRoute,
    public dataService: DataService,
    public alert: AppAlertService,
    public spacesService: SpaceService,
    public modalService: NgbModal,
    // public userService: UserService,
    public msgService: MessagingService,
    public auth: AuthenticationService) {
      // this.logout();
      
    auth.user.subscribe(output1 => {
      this.currentUser = JSON.parse(output1);
      if (this.currentUser) {
        this.apiBODY = { receiver_id: this.currentUser.id };
        this.isUserLoggedIn = true;
        this.get_msg_notification4first_time();
      } else {
        this.isUserLoggedIn = false;
      }
    });
    auth.app_data.subscribe(output1 => {
      this.appData = JSON.parse(output1);
    });

    auth.profile_pic.subscribe(output2 => {
      if (output2 !== '') {
        this.showdp = true;
        this.profile_pic = output2 + '?' + Math.random();
      } else {
        this.showdp = false;
      }
    });
    router.events.subscribe((event: RouterEvent) => {
      if (event instanceof NavigationEnd) {
       this.get_msg_notification();
      };
    });
  }
  ngAfterViewInit(){
    // var iframe = document.createElement('iframe');
    //     iframe.width = '100'; 
    //     iframe.height = '40';
    //     iframe.frameBorder = '0';
    //     iframe.scrolling = "no";
    //     iframe.id = "myFrame";
    //     iframe.src = 'about:blank';
    //     var content = `<span id="siteseal"><script async type="text/javascript"
    //     src="https://seal.godaddy.com/getSeal?sealID=KqayRpk7szcATGXUkf80dPTcRvAt5HmKPO4r6xI9sgJOtUUx9tbIhq5Sjcy0">
    //     </script></span>`
    //     this.sealRef.nativeElement.appendChild(iframe);        
    //     iframe.contentWindow.document.open('text/html', 'replace');
    //     iframe.contentWindow.document.write(content);
    //     iframe.contentWindow.document.close();
    //     setTimeout(()=>{
    //       $('#myFrame').contents().find("img").css("height","25px");
    //       $('#myRef').css("display","block");
    //     },1000)
    //     $('#myFrame').contents().find("body").css("margin","unset");
  }
  ngOnInit() {
    if (localStorage.getItem('user') !== null) {
      this.currentUser = JSON.parse(localStorage.getItem('user'));
      this.getuser();
      this.dummy=localStorage.getItem('dummy');
      this.profile_pic =this.currentUser.profile_image != null? localStorage.getItem('profile_pic'):this.img_url+'/'+this.dummy;
      localStorage.setItem('profile_pic',this.profile_pic);
      this.showdp = (this.profile_pic !== '' && this.profile_pic !== null) ? true : false;
      this.isUserLoggedIn = true;
      this.apiBODY = { receiver_id: this.currentUser.id };
      this.get_msg_notification4first_time();

    } else {
      this.router.events.subscribe((event: Event) => {
        if (event instanceof NavigationEnd) {
          const currentUrl = event.url;
          if ((currentUrl !== '/home') && (currentUrl !== '/login')) {
            //   this.router.navigate(['home']);
          }
        }
      });
    }


    this.appData = JSON.parse(window.localStorage.getItem('appData'));
    this.get_msg_notification();
  }
  getuser(): void {
    this.auth.getuser(this.currentUser.id).then((data: any) => {
      localStorage.setItem('user', JSON.stringify(data['data']));
      this.auth.updateUser(JSON.stringify(data['data']));
     if(data && data.pic){
      localStorage.setItem("dummy",data.pic);
     }
    });
  }
  get_msg_notification4first_time() {
    if (this.isUserLoggedIn) {
      this.msgService.getMSGNotification(this.apiBODY).then((response) => {
        if (response['status']) {
          this.unread_msg_count = response['data'];
          this.unread_notification_count = response['SpaceNotification'].length;
          this.NotificationList = response['SpaceNotification'];
        } else {
          this.unread_msg_count = 0;
          this.unread_notification_count = 0;
          this.NotificationList = [];
        }
      });
    }
  }

  updateSpace(spaceid: string | number){
    const navigationExtras: NavigationExtras = { queryParams: { 'spaceID': spaceid } };
    this.router.navigate(['user-dashboard/modify-space'], navigationExtras);
  }
  get_msg_notification() {
    if (this.isUserLoggedIn) {
      this.msgService.getMSGNotification(this.apiBODY).then((response) => {
        if (response['status']) {
          this.unread_msg_count = response['data'];
          this.unread_notification_count =(response['SpaceNotification']? response['SpaceNotification'].length : 0) + (response['unpublished']?response['unpublished'].length:0);
          this.NotificationList = response['SpaceNotification'];
          
          this.Unpublished = response['unpublished']? response['unpublished']:[];
        } else {
          this.unread_msg_count = 0;
          this.unread_notification_count = 0;
          this.NotificationList = [];
        }
      });
    }
  }

  logout() {
    localStorage.removeItem('user');
    localStorage.removeItem('profile_pic');
    localStorage.clear();
    this.alert.warn('Logged out');
    this.auth.updateUser(null);
    this.currentUser = null;
    this.router.navigate(['home']);
    this.unread_msg_count = 0;
    this.unread_notification_count = 0;
    this.NotificationList = [];
  }
  getIcon(iname){
    const str=iname;
    this.iconN='';
    this.icons.forEach((ele)=>{
      if(str.match(ele.name)){
        this.iconN=ele.class;
      }      
    });
    return this.iconN;
  }
  openlink(link) {
    const str=link;
    this.icons.forEach((ele)=>{
      if(str.match(ele.name)){
        window.open(link, '_blank');
      }
    })
    
  }
  gotoProfile() {
    this.router.navigate(['user-dashboard']);
  }
  doTrim(INtext, INlength) {
    return this.dataService.doTrim(INtext, INlength);
  }

  // notification redirects to respective page
  goto(page_id) {
    switch (page_id) {
      case 1:
        this.clearNotification(1, 0);
        this.router.navigate(['user-dashboard/bookings']);
        break;
      case 2:
        this.clearNotification(2, 0);
        this.router.navigate(['user-dashboard/reservations']);
        break;
      case 3:
        this.clearNotification(3, 0);
        this.router.navigate(['user-dashboard/reservations']);
        break;
      default:
        break;
    }
  }

  // open review for space
  openmodal(content) {
    this.modalRef = this.modalService.open(content);
  }

  // clear the notification
  clearNotification(notification_type, notification_id) {
    this.msgService.clearNotifications(this.currentUser.id, notification_type, notification_id).then((result) => {
      if (result['status']) {
        this.get_msg_notification4first_time();
      } else {
        this.alert.error('Something went wrong.Please Try later');
      }
    });
  }

  getReview(notification) {
    this.spacesService.getSpecificReview(notification.review.id).then((response) => {
      //console.log(response);
      if (response['status']) {
        this.feedbackData = response['data'];
        this.clearNotification(9, notification.id);
      } else {
        this.feedbackData = [];
        this.alert.error('Something went wrong.Please Try later');
      }
    });
  }
  gotoReviewPage(spaceID) {
    const navigationExtras: NavigationExtras = {
      queryParams: { 'spaceID': spaceID }
    };
    this.modalRef.close();
    this.router.navigate(['space-reviews'], navigationExtras);
  }
  checkUser(){
    if(!this.currentUser){
      if(this.auth.registerUser){
        this.currentUser=this.auth.registerUser;
        return true;
      }else{
        return false;
      }     
    }else{
      return true;
    }
  }
}





// notification status
// 1.book space
// 2.approve the booking
// 3.reject the booking
// 9.give space review
